import { Injectable } from '@angular/core';
import { CanActivate} from '@angular/router';
import { Location } from '@angular/common';

import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardLogin implements CanActivate {

    constructor(private _auth: AuthService, private _location: Location ) { }

    canActivate() {

        if (this._auth.isLoggedIn()) {

            return false;
        }

        return true;

    }

}