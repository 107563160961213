<!-- header component -->
<menu-component *ngIf="showMenu"></menu-component>
<!-- header component end -->

<div class="home-login-component">
    <div class="container">
        <div class="error-generic text-center loading" *ngIf="showLoading"><img src="../../../assets/images/rolling.gif" alt="aguarde" width="70"><br/><br/>
            <p>Aguarde</p>
        </div>
        <div class="card card-container" *ngIf="!showLoading">
            <div class="row">
                <div class="col-md-8 col-xs-8">
                    <div class="data-usu">
                        <h4>Olá, <strong>{{ dataUser.name | stripname | ucword }}.</strong></h4>
                        <p>Mantenha seus dados sempre atualizados.</p>
                    </div>
                </div>
                <div class="col-md-4 col-xs-4 text-right">
                    <img class="img-trust" src="../../../assets/images/selo.png" alt="">
                </div>
            </div>
            <hr>

            <form class="form-signin" novalidate autocomplete="off" method="POST" (submit)="onUpdate(dataUser)" >

                <p>CPF <br> <strong>{{ dataUser.cpf | cpffake }}</strong></p>
                <br>
                <p>Nome completo <br> <strong>{{ dataUser.name | ucword }}</strong></p>
                <br>

                <div class="form-group">
                    <span class="fake">{{ dataUser.phone | phonefake }}</span>
                    <label>Celular &nbsp;&nbsp;(somente números)</label>
                    <input (focus)="onFocusPhone()" type="text" name="phone" [(ngModel)]="dataUser.phone" class="form-control input-fake-hidden"
                        maxlength="12">
                    <p class="help-block" *ngIf="errorPhone">
                        <span style="color: red;">{{ errorPhone }}</span>
                    </p>
                </div>

                <div class="form-group">
                    <label class="label-down" for="phrase">Nova senha &nbsp;&nbsp;(6 números)
                        <!-- <input  type="password" name="password"  maxlength="6" class="form-control" id="password" (focus)="onFocusPassword()" [(ngModel)]="dataUser.password"> -->
                        <input  type="password" name="phrase"  maxlength="6" class="form-control" id="phrase" (focus)="onFocusPassword()" [(ngModel)]="dataUser.phrase">
                    </label>
                    <p class="help-block" *ngIf="errorPhrase">
                        <span style="color: red;">{{ errorPhrase }}</span>
                    </p>
                </div>
                <br>

                <div class="form-group">
                    <label class="label-down" for="phraseConfirmation">Confirmar senha
                        <!-- <input  type="password" name="passwordConfirmation" maxlength="6" class="form-control" id="passwordConfirmation" [(ngModel)]="dataUser.passwordConfirmation" (focus)="onFocusPasswordConfirmation()"> -->
                        <input  type="password" name="phraseConfirmation" maxlength="6" class="form-control" id="phraseConfirmation" [(ngModel)]="dataUser.phraseConfirmation" (focus)="onFocusPasswordConfirmation()">
                      </label>
                    <p class="help-block" *ngIf="errorPhraseConfirmation">
                        <span style="color: red;">{{ errorPhraseConfirmation }}</span>
                    </p>

                    <p class="help-block" *ngIf="errorMsg">
                        <span style="color: red;">{{ errorMsg }}</span>
                    </p>
                </div>
                <br>

                <div class="row">
                    <div class="col-md-12 col-xs-12 text-right btn-cancel">
                        <button class="btn btn-primary btn-signin btn-signin-small btn-secundary hide-btn-cancel" type="button" (click)="onCancel()">CANCELAR</button>
                        <button class="btn btn-primary btn-signin btn-signin-small" type="submit" >ATUALIZAR</button>
                    </div>
                </div>
            </form>
            <!-- /form -->
        </div>
        <!-- /card-container -->
    </div>
    <!-- /container -->
</div>


<!-- footer -->
<footer-component *ngIf="showFooter"></footer-component> 
<!-- footer end -->
