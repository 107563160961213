import {Injectable} from '@angular/core';

declare var CryptoJS: any;

@Injectable({
    providedIn: 'root'
})
export class StorageService{
	// required create this salt
	salt: string = '8fbbf95f1e5678899cb285b6051846a7';

	setItem(key: string, value: any):void{

		let encryptedData = CryptoJS.AES.encrypt( JSON.stringify(value) , this.salt);
		localStorage.setItem(key, encryptedData );
	}

	getItem(key: string): any{

		if(localStorage.getItem(key)){

			let bytes         = CryptoJS.AES.decrypt(localStorage.getItem(key).toString(), this.salt );
			let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
			return decryptedData;
		}		
		
		return undefined;
	}

	removeItem(key: string):any {

		if(localStorage.getItem(key)){

			localStorage.removeItem(key);
			
			return true;
		}		
		
		return undefined;
	}

	clear():void {
		localStorage.clear();
	}

}
