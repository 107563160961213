import { Component , OnInit , ViewChild , AfterViewInit , ElementRef } from '@angular/core';

@Component({
	selector   : 'footer-component',
 	templateUrl: './footer.component.html'
})

export class FooterComponent implements OnInit {

	constructor(){

	}

	ngOnInit(): void{

	}

}