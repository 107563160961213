import { Pipe, PipeTransform } from '@angular/core';

@Pipe({

	name:'phonefake'
})
export class PhoneFakePipe implements PipeTransform{

	transform(value: string ){

		if(value ===undefined || !value.length) return value;

		let phoneFake = value.replace(/\D/g,"");
			phoneFake = phoneFake.replace(/(\d{2})(\d)/,"($1) $2");
			phoneFake = phoneFake.replace(/(\d{5})(\d)/,"$1-$2");
			
		return phoneFake;
	}
}
