import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'limitcaractere'
})
export class LimitCaracterePipe implements PipeTransform {

    transform(value: string, limit: number) {
        
        if (value.length < limit) {
            return value;
        }

        let valueLimited: string  = value.substr(0, limit);
        let posFirstSpace = valueLimited.indexOf(" ");
        let valueLimitedFormated: string = valueLimited.substr(0,posFirstSpace);

        return valueLimitedFormated;
 
    }


}