<span defaultOverlayTarget></span>
<!-- header component -->
<menu-component *ngIf="showMenu"></menu-component>
<!-- header component end -->

<div class="home-login-component">
	<div class="container">
		<div class="error-generic text-center loading" *ngIf="showLoading"><img src="../../../assets/images/rolling.gif" alt="aguarde" width="70"><br/><br/><p>Aguarde</p></div>
		<div class="card card-container" *ngIf="!showLoading">
			<div class="row">

				<div class="col-md-8 col-xs-8">
					<div class="data-usu">
						<h4 *ngIf="dataUser.name">Olá, <strong>{{ dataUser.name | ucword | stripname }}</strong></h4>
						<p>Seja bem vindo ao módulo de segurança Santander.</p>
					</div>
				</div>
				<div class="col-md-4 col-xs-4 text-right">
					<img class="img-trust" src="../../../assets/images/selo.png" alt="">
				</div>
			</div>

			<form class="form-signin" novalidate autocomplete="off" (submit)="signin(dataUser)" method="post">

				<div class="form-group">
					<span class="fake">{{  dataUser.cpf | cpffake }}</span>
					<label class="label-down">CPF</label>
					<input type="text" name="cpf" [(ngModel)]="dataUser.cpf"
					(focus)="onFocusCpf()" (ngModelChange)="formataCPF(dataUser.cpf)"
					class="form-control input-fake-hidden" maxlength="14" [disabled]="activeFieldCpf">

					<p class="help-block" *ngIf="errorCpf">
						<span style="color: red;">{{ errorCpf }}</span>
					</p>
				</div>

				<div class="checkbox" *ngIf="showRemember">
					<label>
                        <input type="checkbox" name="rememberCpf" [(ngModel)]="dataUser.remember"> Guardar cpf
                    </label>
				</div>

				<div class="form-group">

					<label class="label-down label-password" for="password">{{ infoUser }}
						<input type="password" name="password" class="form-control password" id="password" [(ngModel)]="dataUser.phrase" (focus)="onFocusPassword()" [maxlength]="maxlengthPhrase">
					</label>


					<p class="help-block" *ngIf="errorPhrase">
						<span style="color: red;">{{ errorPhrase }}</span>
					</p>

					<p class="help-block" *ngIf="errorAuth">
						<span style="color: red;">{{ errorAuth }}</span>
					</p>

				</div>

				<br><br>

				<button class="btn btn-lg btn-primary btn-block btn-signin" type="submit">ENTRAR</button>

			</form>
			<!-- /form -->

			<div class="row" *ngIf="showUpdateRegister">

				<div class="col-md-6 col-xs-12">
					<br>
					<a (click)="goToToken()" class="forgot-password text-left">
		               Esqueci minha senha
		            </a>
				</div>

				<div class="col-md-6 col-xs-12 container-tooltip">
					<br>
					<a (click)="goToUpdateRegister(dataUser)" (mouseenter)="configTooltip($event)" (mouseleave)="configTooltip($event)" class="forgot-password text-right">
						<em class="glyphicon glyphicon-info-sign"></em> Atualizar cadastro
					</a>
					<div class="tooltip-custom" style="display:none;">
						Informe sua senha para alterar o seu celular associados ao seu cadastro
					</div>
				</div>

			</div>

			<div class="row" *ngIf="!showUpdateRegister">
				<div class="col-md-12 col-xs-12 text-center">
					<br> Caso você esqueceu sua senha, acesse o portal santander<br><a [href]="linkForgotPhrase" target="blank" class="forgot-password">https://www.santander.com.br</a>
				</div>
			</div>

		</div>
	</div>

</div>

<!-- footer -->
<footer-component *ngIf="showFooter"></footer-component>
<!-- footer end -->
