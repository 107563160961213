<!-- header component -->
<menu-component *ngIf="showMenu"></menu-component>
<!-- header component end -->


<div class="home-login-component" *ngIf="dataUser">
	<div class="container">
		<div class="card card-container">
			<div class="row">
				<!--
				<div class="col-md-12">
					<p class="icon-back" (click)="goBack()"><em class="glyphicon glyphicon-chevron-left"></em></p>
				</div>
				-->

				<div class="col-md-8 col-xs-8">
					<div class="data-usu" [innerHTML]="contentTitle">
					</div>
				</div>

				<div class="col-md-4 col-xs-4 text-right">
					<img class="img-trust" src="../../../assets/images/selo.png" alt="">
				</div>
			</div>

			<div class="row">
				<div class="col-md-12" [innerHTML]="contentInfo">
				</div>
			</div>

			<button class="btn btn-lg btn-primary btn-block btn-signin" type="button" (click)="generateTokenToSend()">ENVIAR CÓDIGO SMS TOKEN</button><br>
			<p class="help-block text-center" *ngIf="showLoading">aguarde..</p>
			<p class="help-block" *ngIf="errorMsg">
				<span style="color: red;">{{ errorMsg }}</span>
			</p>
		</div>
		<!-- /card-container -->
	</div>
	<!-- /container -->
</div>


<!-- footer -->
<footer-component *ngIf="showFooter"></footer-component>
<!-- footer end -->