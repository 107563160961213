import { Component, HostListener, OnInit } from '@angular/core';

import { StorageService } from './services/storage.service';
import { LogService } from './services/log.service';

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(private _storage: StorageService, private _log: LogService) {}

  ngOnInit(): void {
    this._storage.clear();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(evt: any) {
    this._log.print('clear data users and caches');
    this._storage.clear();
  }
}
