import { Component, OnInit  } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '../services/settings.service';
import { AuthService } from '../services/auth.service';
import { WindowRef } from '../services/windowRef.service';
import { StorageService} from '../services/storage.service';
import { LogService } from '../services/log.service';
// @ts-ignore
import { version } from '../../../package.json';

import {DataInputs } from '../contracts/data-inputs.interface';

declare let DLECC: any;
@Component({

	selector: 'home',
	templateUrl: './home.component.html',
	providers: [AuthService]

})

export class HomeComponent implements OnInit {

	// cpf: any = '62793345890'; // 258369
	cpf: any = '56685734868';
	name: any = 'cliente santander';
	phone: any = '119972100744';
	urlSuccess: any = 'https://viverebrasil.com.br/autoatendimentopfhml/imobiliario/portal-cliente';
	urlError: any = 'https://viverebrasil.com.br/santanderpfpchml/#/resumo-da-proposta/error';
	partner: any = 'imobiliario';
	dataParams: any;
	libCrypt: any;
	localPubKey: any;
	contextId: any = '52f7accef9eab1db5ed275640454a4f4';
	forbbidenField: any = '123456';
	dataEncrypted: any;
    public versionAuth: string;


	constructor(
		private _router: Router,
		private _settings: SettingsService,
		private _auth: AuthService,
		private _storage: StorageService,
		private _windowRef: WindowRef,
		private _log: LogService,
		) { 
		this.versionAuth = version;
	}

	ngOnInit() {
		this._storage.clear();
		this.libCrypt = DLECC;
		this.localPubKey = this.libCrypt.init();
		
	}


	sendPf() {
		this._auth.changeKey({
			clientPublicKey: this.localPubKey,
			contextId: this.contextId,
			system: 'PAY'
		}).then(result => {

			let cpfCrypt = this.libCrypt.encryptToApp(this.cpf, result.serverPublicKey);
			let urlSuccessCrypt = this.libCrypt.encryptToApp(this.urlSuccess, result.serverPublicKey);
			let urlErrorCrypt = this.libCrypt.encryptToApp(this.urlError, result.serverPublicKey);
			let partnerCrypt = this.libCrypt.encryptToApp(this.partner, result.serverPublicKey);
			
			let dataParams = {
				cpf: cpfCrypt.toString(),
				urlSuccess: urlSuccessCrypt.toString(),
				urlError: urlErrorCrypt.toString(),
				ticket: result.ticket,
				partner: partnerCrypt.toString()
			};
				
			
			this.dataParams = dataParams;
			this._router.navigate(['/simulator']).then(() => {
				
				let urlcrypted = '/#/loginpf?cpf=' + dataParams.cpf +
				'&urlSuccess=' + dataParams.urlSuccess +
				'&urlError=' + dataParams.urlError +
				'&ticket=' + dataParams.ticket +
				'&partner=' + dataParams.partner +
				'&doDebug=show'

				window.top.location.href = urlcrypted;

			});
		});
	}

	send() {

		this._auth.changeKey({
			clientPublicKey: this.localPubKey,
			contextId: this.contextId,
			system: 'PAY'

		}).then(result => {

			let cpfCrypt = this.libCrypt.encryptToApp(this.cpf, result.serverPublicKey);
			let nameCrypt = this.libCrypt.encryptToApp(this.name, result.serverPublicKey);
			let phoneCrypt = this.libCrypt.encryptToApp(this.phone, result.serverPublicKey);
			let urlSuccessCrypt = this.libCrypt.encryptToApp(this.urlSuccess, result.serverPublicKey);
			let urlErrorCrypt = this.libCrypt.encryptToApp(this.urlError, result.serverPublicKey);
			let partnerCrypt = this.libCrypt.encryptToApp(this.partner, result.serverPublicKey);

			

			let dataParams = {
				cpf: cpfCrypt.toString(),
				name: nameCrypt.toString(),
				phone: phoneCrypt.toString(),
				urlSuccess: urlSuccessCrypt.toString(),
				urlError: urlErrorCrypt.toString(),
				ticket: result.ticket,
				partner: partnerCrypt.toString()
			};

			// this.dataParams = dataParams;
			this._router.navigate(['/simulator']).then(() => {

				let urlcrypted = '/#/login?name=' + dataParams.name +
					'&cpf=' + dataParams.cpf +
					'&phone=' + dataParams.phone +
					'&urlSuccess=' + dataParams.urlSuccess +
					'&urlError=' + dataParams.urlError +
					'&ticket=' + dataParams.ticket +
					'&partner=' + dataParams.partner
					// '&doDebug=show'

				window.top.location.href = urlcrypted;

			});

		});

	}
	
	sendMPf() {
		this._auth.changeKey({
			clientPublicKey: this.localPubKey,
			contextId: this.contextId,
			system: 'PAY'
		}).then(result => {

			let cpfCrypt = this.libCrypt.encryptToApp(this.cpf, result.serverPublicKey);
			let urlSuccessCrypt = this.libCrypt.encryptToApp(this.urlSuccess, result.serverPublicKey);
			let urlErrorCrypt = this.libCrypt.encryptToApp(this.urlError, result.serverPublicKey);
			let partnerCrypt = this.libCrypt.encryptToApp(this.partner, result.serverPublicKey);

			let dataParams = {
				cpf: cpfCrypt.toString(),
				urlSuccess: urlSuccessCrypt.toString(),
				urlError: urlErrorCrypt.toString(),
				ticket: result.ticket,
				partner: partnerCrypt.toString()
			};

			this.dataParams = dataParams;
			this._router.navigate(['/simulator']).then(() => {

				let urlcrypted = '/#/loginmpf?cpf=' + dataParams.cpf +
					'&urlSuccess=' + dataParams.urlSuccess +
					'&urlError=' + dataParams.urlError +
					'&ticket=' + dataParams.ticket +
					'&partner=' + dataParams.partner +
					'&doDebug=show'

				window.top.location.href = urlcrypted;

			});
		});
	}
	
	encryptData() {

		this._auth.changeKey({
			clientPublicKey: this.localPubKey,
			contextId: this.contextId,
			system: 'LNC'

		}).then(result => {

			let cpfCrypt = this.libCrypt.encryptToApp(this.cpf, result.serverPublicKey);
			let nameCrypt = this.libCrypt.encryptToApp(this.name, result.serverPublicKey);
			let phoneCrypt = this.libCrypt.encryptToApp(this.phone, result.serverPublicKey);
			let urlSuccessCrypt = this.libCrypt.encryptToApp(this.urlSuccess, result.serverPublicKey);
			let urlErrorCrypt = this.libCrypt.encryptToApp(this.urlError, result.serverPublicKey);
			let partnerCrypt = this.libCrypt.encryptToApp(this.partner, result.serverPublicKey);
			let phraseCrypt = this.libCrypt.encryptToMF(this.forbbidenField, result.serverPublicKey);
			let phraseCrypt2 = this.libCrypt.encryptToApp(this.forbbidenField,result.serverPublicKey);

			this.dataEncrypted = {
				cpf: cpfCrypt.toString(),
				name: nameCrypt.toString(),
				phone: phoneCrypt.toString(),
				urlSuccess: urlSuccessCrypt.toString(),
				urlError: urlErrorCrypt.toString(),
				ticket: result.ticket,
				partner: partnerCrypt.toString(),
				passwordComToMF: phraseCrypt.toString(),
				passwordComToApp : phraseCrypt2.toString(),
				system:'LNC'
			};
		});
	}

}
