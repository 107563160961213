import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { SettingsService } from '../services/settings.service';
import { StorageService } from '../services/storage.service';
import { ValidateService } from '../services/validate.service';
import { AuthService } from '../services/auth.service';
import { WindowRef } from '../services/windowRef.service';
import { LogService } from '../services/log.service';

@Component({
	
	selector: 'new-password',
	templateUrl: './new-password.component.html',
	providers: [ValidateService, AuthService]

})
export class NewPasswordComponent implements OnInit {

	showMenu: boolean = false;
	showFooter: boolean = false;
	dataUser: any;
	typeUser: any;
	errorToken: any = false;
	errorPhrase: any = false;
	errorPhraseConfirmation: any = false;
	libCrypt: any;
    localPubKey: any;
	errorMsg: any = false;
	showLoading: any = false;
	credentials: any = false;

	constructor(
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _location: Location,
		private _settings: SettingsService,
		private _storage: StorageService,
		private _validate: ValidateService,
		private _auth: AuthService,
		private _windowRef: WindowRef,
		private _log: LogService
	) { }

	ngOnInit(): void {
		this._configDataUser();
		this._configTypeUser();
		this._configCredentials();

		this.libCrypt = this._windowRef.nativeWindow.libdlecc;
        this.localPubKey = this.libCrypt.init();

	}

	goBack(): void {
        this._router.navigate(['/new-password']).then(() => {
            window.top.location.href = this.dataUser.urlError;
        });
    }

	saveNewPassword(data: any) { 

		if (!this._validate.checkNumbers(data.token)) {
			this.errorToken = this._settings.ERROR_MESSAGES.TOKEN;
			return false;
		}

		if(('phrase' in data) == false ){

			this.errorPhrase = this._settings.ERROR_MESSAGES.PHRASE;
			return false;
		}

		if (!this._validate.checkPassword(data.phrase)) {
			this.errorPhrase = this._settings.ERROR_MESSAGES.PHRASE;
			return false;
		}
		if (!this._validate.checkPasswordConfirmation(data.phrase, data.phraseConfirmation)) {
			this.errorPhraseConfirmation = this._settings.ERROR_MESSAGES.PHRASE_CONFIRMATION;
			return false;
		}

		this.errorToken = false;
		this.errorPhrase = false;
		this.errorPhraseConfirmation = false;

		this.showLoading = true;

		this._auth.changeKey({
			clientPublicKey: this.localPubKey,
            contextId: this._settings.CONTEXT_ID,
            system: 'MIA'
		}).then(

			resultChangeKey => {
				
				let cpfEncrypted = this.libCrypt.encryptToApp(data.cpf, resultChangeKey.serverPublicKey).toString();

				let proposalId: string = data.cpf;
				let tokenEncrypted = this.libCrypt.encryptToApp(data.token, resultChangeKey.serverPublicKey).toString();
				let proposalIdEncrypted = this.libCrypt.encryptToApp(proposalId, resultChangeKey.serverPublicKey).toString();
				let systemCode = '0000000000000000000';
				let horario = this._storage.getItem('referOper');
				let channelEcrypted = this.libCrypt.encryptToApp('62', resultChangeKey.serverPublicKey).toString();
				let personTypeEncrypted = this.libCrypt.encryptToApp('F', resultChangeKey.serverPublicKey).toString();
				let referOperEncrypted = this.libCrypt.encryptToApp(horario, resultChangeKey.serverPublicKey).toString();

				this._auth.validateTokenSMS({
					"channelId": channelEcrypted,
                    "personType": personTypeEncrypted,
					"systemCode": 'MIA',
					"referOper":  referOperEncrypted,
                    "documentNumber": cpfEncrypted,
                    "otp": tokenEncrypted,
                    "ticket": resultChangeKey.ticket
				}).then(
					resultValidateToken => {

						if(resultValidateToken.retCode == 0) {
							this._auth.changeKey({
								clientPublicKey: this.localPubKey,
								contextId: this._settings.CONTEXT_ID,
								system: 'LNC'}).then (
								resultNewChangeKey => {
	
									let cpfEncrypted = this.libCrypt.encryptToApp(data.cpf, resultNewChangeKey.serverPublicKey).toString();
									let phraseEncrypted = this.libCrypt.encryptToApp(data.phrase, resultNewChangeKey.serverPublicKey).toString();
	
									this._auth.forgotPassword({
										documentNumber: cpfEncrypted,
										password: phraseEncrypted,
										system: 'LNC',
										ticket: resultNewChangeKey.ticket
									}).then(
										resultForgot => {
											this._goAuth(data);
										},
										errorForgot => {
											// this._log.print('error from forgot password: Ocorreu um error tente novamente');
											this.showLoading = false;
											this.errorMsg = errorForgot.message;
										}
									);
								},
								errorNewChangeKey => {
									// this._log.print('error from new change key:', errorNewChangeKey);
									this.showLoading = false;
									this.errorMsg = this._settings.ERROR_MESSAGES.GENERIC;
								}
							);
						} else {
							// this._log.print('error from validate token: Ocorreu um erro tente novamente');
							this.showLoading = false;
							this.errorToken = this._settings.ERROR_MESSAGES.TOKEN;
						}
					},
					errorValidateToken => {
						// this._log.print('error from validate token:', errorValidateToken);
						this.showLoading = false;
						this.errorToken = this._settings.ERROR_MESSAGES.TOKEN;
					}
					);
			},
			errorChangeKey => {
				// this._log.print('error from change key:', errorChangeKey);
				this.showLoading = false;
				this.errorMsg = this._settings.ERROR_MESSAGES.GENERIC;

			}
		);
	}

	sendSmsToken(): void {
		this._router.navigate(['/token']); 
	}

	onFocusToken() {
		this.errorToken = false;
	}

	onFocusPassword() {
		this.errorPhrase = false;
	}

	onFocusPasswordConfirmation() {
		this.errorPhraseConfirmation = false;
	}

	public _configDataUser() {

		if( this._storage.getItem('storeUser') != undefined )
		{
			this.dataUser = this._storage.getItem('storeUser');
		}else{
			this._router.navigate(['/']);
		}
	}

	public _configTypeUser() {

		if( this._storage.getItem('storeType') != undefined )
		{
			this.typeUser = this._storage.getItem('storeType');
		}else{
			this._router.navigate(['/']);
		}

	}
	public _goAuth(data: any) {

		this._auth.changeKey({
			clientPublicKey: this.localPubKey,
			contextId: this._settings.CONTEXT_ID,
			system: 'PAY'

		}).then(resultChangeKey => {

			let cpf = this.libCrypt.encryptToApp(data.cpf, resultChangeKey.serverPublicKey).toString();
			let phrase = this.libCrypt.encryptToMF(data.phrase, resultChangeKey.serverPublicKey).toString();

			this._auth.authenticate({
				documentNumber: cpf,
				password: phrase,
				credential: 'NAO_CLIENTE',
				system: 'PAY',
				ticket: resultChangeKey.ticket

			}).then(
				resultAuth => {
					this._router.navigate(['/new-password']).then(() => {

						let urlSuccess = this._storage.getItem('storeUser') != undefined ? this._storage.getItem('storeUser').urlSuccess : false;
						this._clearFields();
						if (urlSuccess && resultAuth.token) {
							this._redirectUrlSucess(urlSuccess, resultAuth);
						} else {
							// this._log.print('error from cause:', {
							// 	urlSuccess: urlSuccess,
							// 	token: resultAuth.token
							// });
							this.showLoading = false;
							this._router.navigate(['/alert']);
						}
					});
				},
				errorAuth => {
					// this._log.print("error from auth:", errorAuth);
					this.showLoading = false;
					this._clearFields();
					if (!this.dataUser || !this.dataUser.urlError) {
						this._router.navigate(['/alert']);
					} else {
						window.top.location.href = this.dataUser.urlError;
					}
				});

		}, errorChangeKey => {

			this._log.print("error from change key:", errorChangeKey);
			this.showLoading = false;
			if (!this.dataUser || !this.dataUser.urlError) {
				this._router.navigate(['/alert']);
			} else {
				window.top.location.href = this.dataUser.urlError;
			}

		});

	}

	public _redirectUrlSucess(urlSuccess: string, resultAuth: any) {
		let path = encodeURI(urlSuccess);
		let tokenEncoded = encodeURIComponent(resultAuth.token);
		let credentials = this.credentials == false ? 'NAO_CLIENTE': this.credentials;
		let href = path + "?token=" + tokenEncoded + "&typeClient=" + credentials;
		window.top.location.href = href;

	}

	public _clearFields() {
		this.dataUser = {};
		this._storage.clear();
	}

	public _configCredentials() {

		if( this._storage.getItem('credentials') != undefined )
		{
        	let credentials = this._storage.getItem('credentials');
		    this.credentials = credentials.type;
		}else{
			this._router.navigate(['/']);
		}
    }
}



