import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ValidateService {

	checkCpf(cpf: any): boolean {

		let numeros: any;
		let digitos: any;
		let soma: any;
		let i: number;
		let resultado: any;
		let digitos_iguais: number = 1;

		if (cpf.length < 11) {

			return false;
		}

		for (i = 0; i < cpf.length - 1; i++) {

            if (cpf.charAt(i) != cpf.charAt(i + 1)) {

                digitos_iguais = 0;
                break;
            }
		}

		if (!digitos_iguais) {

			numeros = cpf.substring(0, 9);
			digitos = cpf.substring(9);
			soma = 0;

			for (i = 10; i > 1; i--)
                soma += numeros.charAt(10 - i) * i;

			resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

			if (resultado != digitos.charAt(0))
                return false;

			numeros = cpf.substring(0, 10);

			soma = 0;

			for (i = 11; i > 1; i--)
                soma += numeros.charAt(11 - i) * i;

			resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

			if (resultado != digitos.charAt(1))
                return false;

			return true;
		}
		else {
			return false;
		}
	}

	checkEmailValid(email: any): boolean {
		let er = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
		if (!er.test(email)) return false;

		return true;
	}

	checkNumbers(value: any): boolean {

		let er = /[0-9]/;

		if (er.test(value)) {
			return true;
		}
		return false;
	}

	checkPassword(value: any): boolean {

		if (value == "") return false;
		if (!this.checkNumbers(value)) return false;
		if (value.length != 6) return false;

		return true;
	}

	checkPasswordConfirmation(pass1: any, pass2: any): boolean {
		if (pass1 == pass2) return true;

		return false;
	}

	checkPhone(phone: any): boolean {

		if (phone.length < 11) return false;
		if (!this.checkNumbers(phone)) return false;

		return true;
	}

	checkName(name: any): boolean {
		if (!name || name == "") return false;
		if (/([0-9])/g.test(name)) return false;
		return true;
	}

	checkPasswordWithAlpha(value: any): boolean {

		if (value == "") return false;
		if (value.length  < 6 || value.length > 8) return false;

		return true;
	}

}