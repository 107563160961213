<!-- header component -->
<menu-component *ngIf="showMenu"></menu-component>
<!-- header component end -->

<div class="home-login-component" *ngIf="dataUser">
    <div class="container">
        <div class="error-generic text-center loading" *ngIf="showLoading"><img src="../../../assets/images/rolling.gif" alt="aguarde" width="70"><br/><br/>
            <p>Aguarde</p>
        </div>
        <div class="card card-container" *ngIf="!showLoading">
            <div class="row">
                <div class="col-md-8 col-xs-8">
                    <div class="data-usu">
                        <h4><strong>Cadastro de Usuário</strong></h4>
                        <p>Preencha os campos abaixo com código SMS Token enviado para o seu celular, para confirmar a criação
                            de seu cadastro.</p>
                        <p>O SMS Token foi enviado para o celular: <strong>{{ dataUser.phone | phoneprotected }}</strong></p>
                        <p>&nbsp;</p>
                    </div>
                </div>
                <div class="col-md-4 col-xs-4 text-right">
                    <img class="img-trust" src="../../../assets/images/selo.png" alt="">
                </div>
            </div>
            <hr>

            <form class="form-signin" novalidate autocomplete="off" method="POST" (submit)="onSave(dataUser)">

                <div class="form-group">
                    <label class="label-down">Nome</label>
                    <input (focus)="onFocusName()" type="text" name="name" [(ngModel)]="dataUser.name" class="form-control" disabled>
                    <p class="help-block" *ngIf="errorName">
                        <span style="color: red;">{{ errorName }}</span>
                    </p>
                </div>
                <br>

                <div class="form-group">
                    <span class="fake">{{  dataUser.cpf | cpffake }}</span>
                    <label class="label-down">CPF</label>
                    <input type="text" name="cpf" [(ngModel)]="dataUser.cpf" (focus)="onFocusCpf()" class="form-control input-fake-hidden" maxlength="11"
                        disabled>
                    <p class="help-block" *ngIf="errorCpf">
                        <span style="color: red;">{{ errorCpf }}</span>
                    </p>
                </div>
                <br>

                <div class="form-group">
                    <span class="fake">{{ dataUser.phone | phonefake }}</span>
                    <label>Celular &nbsp;&nbsp;(somente números)</label>
                    <input (focus)="onFocusPhone()" type="text" name="phone" [(ngModel)]="dataUser.phone" class="form-control input-fake-hidden"
                        maxlength="12" disabled>
                    <p class="help-block" *ngIf="errorPhone">
                        <span style="color: red;">{{ errorPhone }}</span>
                    </p>
                </div>
                <br>

                <div class="form-group">
                    <label class="label-down">Código SMS Token</label>
                    <input (focus)="onFocusToken()" type="text" name="smsToken" [(ngModel)]="dataUser.token" class="form-control">
                    <p class="help-block" *ngIf="errorToken">
                        <span style="color: red;">{{ errorToken }}</span>
                    </p>
                </div>
                <br>

                <div class="form-group">
                    <label class="label-down" for="phrase">Senha &nbsp;&nbsp;(6 números)
                        <input type="password" name="phrase" maxlength="6" id="phrase" class="form-control" [(ngModel)]="dataUser.phrase" (focus)="onFocusPassword()">
                        <!-- <input type="password" name="password" maxlength="6" id="password" class="form-control" [(ngModel)]="dataUser.password" (focus)="onFocusPassword()"> -->
                    </label>
                    <p class="help-block" *ngIf="errorPhrase">
                        <span style="color: red;">{{ errorPhrase }}</span>
                    </p>
                </div>
                <br>

                <div class="form-group">
                    <label class="label-down" for="phraseConfirmation">Confirmar senha
                        <!-- <input type="password" name="passwordConfirmation" maxlength="6" class="form-control" id="passwordConfirmation" [(ngModel)]="dataUser.passwordConfirmation" (focus)="onFocusPasswordConfirmation()"> -->
                        <input type="password" name="phraseConfirmation" maxlength="6" class="form-control" id="phraseConfirmation" [(ngModel)]="dataUser.phraseConfirmation" (focus)="onFocusPasswordConfirmation()">
                    </label>
                    <p class="help-block" *ngIf="errorPhraseConfirmation">
                        <span style="color: red;">{{ errorPhraseConfirmation }}</span>
                    </p>
                    <p class="help-block" *ngIf="errorMsg">
                        <span style="color: red;">{{ errorMsg }}</span>
                    </p>
                </div>
                <br>

                <div class="row">
                    <div class="col-md-5 col-xs-6">
                        <a (click)="sendSmsToken()" class="forgot-password">
                            <em class="glyphicon glyphicon-ok-sign"></em> Reenviar SMS Token
                        </a>
                    </div>
                    <div class="col-md-7 col-xs-6 btn-cancel">
                        <button class="btn btn-primary btn-signin btn-signin-small btn-secundary hide-btn-cancel" type="button" (click)="onCancel()">CANCELAR</button>
                        <button class="btn btn-primary btn-signin btn-signin-small" type="submit">SALVAR</button>
                    </div>
                </div>

            </form>
            <!-- /form -->
        </div>
        <!-- /card-container -->
    </div>
    <!-- /container -->
</div>


<!-- footer -->
<footer-component *ngIf="showFooter"></footer-component>
<!-- footer end -->
