import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { Location } from '@angular/common';

import { SettingsService } from '../services/settings.service';
import { StorageService } from '../services/storage.service';
import { ValidateService } from '../services/validate.service';
import { WindowRef } from '../services/windowRef.service';
import { AuthService } from '../services/auth.service';
import { LogService } from '../services/log.service';

import { DataInputs } from '../contracts/data-inputs.interface';
import { TranslateRequest } from '../contracts/translate-request.interface';

@Component({
  selector: 'loginmpf',
  templateUrl: './loginmpf.component.html',
  providers: [ValidateService, AuthService],
})
export class LoginMPfComponent implements OnInit, AfterViewInit {
  showMenu: boolean = false;
  showFooter: boolean = false;
  showRemember: boolean = false;
  showUpdateRegister: boolean = false;
  infoUser: string = 'Senha do internet banking';
  // dataUser: any = { cpf: '', password: null };
  dataUser: any = { cpf: '', phrase: '' };
  typeUser: any = 1;
  errorCpf: any = false;
  errorPhrase: any = false;
  linkForgotPassword: any;
  libCrypt: any;
  localPubKey: any;
  showLoading: boolean = true;
  errorAuth: any = false;
  activeFieldCpf: boolean = false;
  controFirstAccess: boolean = false;
  maxlengthPassword: any = 6;
  credentials: any = false;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    // private _location: Location,
    private _settings: SettingsService,
    private _storage: StorageService,
    private _validate: ValidateService,
    private _windowRef: WindowRef,
    private _auth: AuthService,
    private _log: LogService,
  ) {}

  ngOnInit(): void {
    this.libCrypt = this._windowRef.nativeWindow.libdlecc;
    this.localPubKey = this.libCrypt.init();
    this._checkParamsUrl();
    this.linkForgotPassword = this._settings.LINK_FORGOT_PHRASE;
    // this.showLoading = false;
  }

  ngAfterViewInit(): void {}

  signin(data: any) {
    if (!this._validate.checkCpf(data.cpf)) {
      this.errorCpf = this._settings.ERROR_MESSAGES.CPF;
      return false;
    }

    this.errorCpf = false;
    this.errorPhrase = false;
    this.showLoading = true;

    this._auth
      .changeKey({
        clientPublicKey: this.localPubKey,
        contextId: this._settings.CONTEXT_ID,
        system: 'PAY',
      })
      .then(
        resultChangeKey => {
          let cpfEncrypted = this.libCrypt.encryptToApp(data.cpf, resultChangeKey.serverPublicKey).toString();
          let passwordEncrypted = this.libCrypt.encryptToMF(data.phrase, resultChangeKey.serverPublicKey).toString();

          this._auth
            .checkTypeUser({
              documentNumber: cpfEncrypted,
              system: 'PAY',
              ticket: resultChangeKey.ticket,
              channel: '06',
            })
            .then(
              resultTypeUser => {
                if (resultTypeUser.credentials.length == 0) {
                  this.showLoading = false;
                  this.errorAuth = this._settings.ERROR_MESSAGES.NOT_CLIENT;
                } else if (resultTypeUser.credentials[0] == 'NAO_CLIENTE') {
                  this._log.print('init authenticate process to user type (2):');
                  this._auth
                    .authenticate({
                      documentNumber: cpfEncrypted,
                      password: passwordEncrypted,
                      credential: resultTypeUser.credentials[0],
                      system: 'PAY',
                      ticket: resultTypeUser.ticket,
                    })
                    .then(
                      resultAuth => {
                        let response = {
                          resultAuth: resultAuth,
                          typeClient: 'NAO_CLIENTE',
                        };

                        window.parent.postMessage(response, '*');
                        let urlSuccess =
                          this._storage.getItem('storeUser') != undefined
                            ? this._storage.getItem('storeUser').urlSuccess
                            : false;
                        this._clearFields();
                        if (urlSuccess && resultAuth.token) {
                          this._redirectUrlSucess(urlSuccess, resultAuth);
                        } else {
                          this._router.navigate(['/login']).then(() => {
                            this.showLoading = false;
                            this._router.navigate(['/alert']);
                          });
                        }
                      },
                      errorAuth => {
                        this._log.print('error from auth:', errorAuth);
                        this.showLoading = false;

                        if (errorAuth.httpStatus == 401) {
                          if (errorAuth.statusCode == -60) {
                            window.parent.postMessage(this._settings.ERROR_MESSAGES.ACTION_REQUIRED, '*');
                            // window.parent.postMessage(
                            //   this._settings.ERROR_MESSAGES.ACTION_REQUIRED,
                            //   'https://viverebrasil.com.br/portalclientehml/',
                            // );
                            this.errorAuth = this._settings.ERROR_MESSAGES.ACTION_REQUIRED;
                          } else {
                            window.parent.postMessage(this._settings.ERROR_MESSAGES.ACTION_REQUIRED, '*');
                            // window.parent.postMessage(
                            //   this._settings.ERROR_MESSAGES.USER_OR_PHRASE_INVALID,
                            //   'https://viverebrasil.com.br/portalclientehml/',
                            // );
                            this.errorAuth = this._settings.ERROR_MESSAGES.USER_OR_PHRASE_INVALID;
                          }
                        } else {
                          this._clearFields();
                          if (!this.dataUser || !this.dataUser.urlError) {
                            this._router.navigate(['/login']).then(() => {
                              this._router.navigate(['/alert']);
                            });
                          } else {
                            window.top.location.href = this.dataUser.urlError;
                          }
                        }
                      },
                    );
                } else {
                  this._log.print('init authenticate process to user type (1):');
                  this._auth
                    .authenticateClient({
                      documentNumber: cpfEncrypted,
                      password: passwordEncrypted,
                      credential: resultTypeUser.credentials[0],
                      system: 'PAY',
                      ticket: resultTypeUser.ticket,
                    })
                    .then(
                      resultAuth => {
                        let response = {
                          resultAuth: resultAuth,
                          typeClient: 'INTERNET_BANKING',
                        };
                        // window.parent.postMessage(resultAuth, 'https://viverebrasil.com.br/portalclientehml/');
                        window.parent.postMessage(response, '*');
                        // let urlSuccess = `${this.dataUser.urlSuccess}?retCode=1&token=${resultAuth.token}&typeClient=${
                        //   this.dataUser.type
                        // }`;
                        this._clearFields();
                        // window.top.location.href = urlSuccess;
                      },
                      errorAuth => {
                        this._log.print('error from auth:', errorAuth);

                        this.showLoading = false;

                        if (errorAuth.status == 401) {
                          if (errorAuth.error.statusCode == -60) {
                            this.errorAuth = this._settings.ERROR_MESSAGES.ACTION_REQUIRED;
                          } else {
                            this.errorAuth = this._settings.ERROR_MESSAGES.USER_OR_PHRASE_INVALID;
                          }
                        } else {
                          this._clearFields();
                          if (!this.dataUser || !this.dataUser.urlError) {
                            this._router.navigate(['/login']).then(() => {
                              this._router.navigate(['/alert']);
                            });
                          } else {
                            window.top.location.href = this.dataUser.urlError;
                          }
                        }
                      },
                    );
                }
              },
              errorTypeUser => {
                this._log.print('error from type user:', errorTypeUser);
                this.showLoading = false;

                if (errorTypeUser.statusCode == 412) {
                  this.errorAuth = this._settings.ERROR_MESSAGES.NOT_CLIENT;
                } else {
                  this.errorAuth = this._settings.ERROR_MESSAGES.AUTH;
                }
              },
            );
        },
        errorChangeKey => {
          this._log.print('error from change key:', errorChangeKey);

          this.showLoading = false;
          if (!this.dataUser || !this.dataUser.urlError) {
            this._router.navigate(['/login']).then(() => {
              this._router.navigate(['/alert']);
            });
          } else {
            window.top.location.href = this.dataUser.urlError;
          }
        },
      );
  }

  goBack() {
    this._router.navigate(['/login']).then(() => {
      window.top.location.href = this.dataUser.urlError;
    });
  }

  onFocusCpf(): void {
    this.errorCpf = false;
    this.errorAuth = false;
  }

  onFocusPassword(): void {
    this.errorPhrase = false;
    this.errorAuth = false;
  }

  goToToken(): any {
    if (this.getTypeUser() == this._settings.TYPE_USER.NOT_CLIENT_REGISTER) {
      this._router.navigate(['/login']).then(() => {
        this._router.navigate(['/token']);
      });
      return false;
    }
  }

  goToUpdateRegister(data: any) {
    if (!this._validate.checkCpf(data.cpf)) {
      this.errorCpf = this._settings.ERROR_MESSAGES.CPF;
      return false;
    }

    if ('phrase' in data == false) {
      this.errorPhrase = this._settings.ERROR_MESSAGES.PHRASE;
      return false;
    }

    if (!this._validate.checkPassword(data.phrase)) {
      this.errorPhrase = this._settings.ERROR_MESSAGES.PHRASE;
      return false;
    }

    this.errorCpf = false;
    this.errorPhrase = false;
    this.showLoading = true;

    this._auth
      .changeKey({
        clientPublicKey: this.localPubKey,
        contextId: this._settings.CONTEXT_ID,
        system: 'PAY',
      })
      .then(
        resultChangeKey => {
          let cpf = this.libCrypt.encryptToApp(data.cpf, resultChangeKey.serverPublicKey).toString();
          let password = this.libCrypt.encryptToMF(data.phrase, resultChangeKey.serverPublicKey).toString();

          this._auth
            .authenticate({
              documentNumber: cpf,
              password: password,
              credential: 'NAO_CLIENTE',
              system: 'PAY',
              ticket: resultChangeKey.ticket,
            })
            .then(
              resultAuth => {
                this._storage.setItem('storeUser', data);
                this._storage.setItem('storeToken', { token: resultAuth.token });
                this._router.navigate(['/login']).then(() => {
                  this._router.navigate(['/update-register']);
                });
              },
              errorAuth => {
                this._log.print('error from auth:', errorAuth);

                this.showLoading = false;

                if (errorAuth.httpStatus == 401) {
                  if (errorAuth.statusCode == -60) {
                    this.errorAuth = this._settings.ERROR_MESSAGES.ACTION_REQUIRED;
                  } else {
                    this.errorAuth = this._settings.ERROR_MESSAGES.USER_OR_PHRASE_INVALID;
                  }
                } else {
                  this._clearFields();
                  if (!this.dataUser || !this.dataUser.urlError) {
                    this._router.navigate(['/login']).then(() => {
                      this._router.navigate(['/alert']);
                    });
                  } else {
                    window.top.location.href = this.dataUser.urlError;
                  }
                }
              },
            );
        },
        errorChangeKey => {
          this._log.print('error from change key:', errorChangeKey);
          this.showLoading = false;
          this.errorAuth = errorChangeKey.message;
        },
      );
  }

  getTypeUser() {
    return this.typeUser;
  }

  configTooltip(event: any) {
    let el: any = event.target.nextElementSibling;

    if (el.getAttribute('data-tooltip-custom') == null) {
      el.style.display = 'block';
      el.setAttribute('data-tooltip-custom', true);
    } else {
      el.style.display = 'none';
      el.removeAttribute('data-tooltip-custom');
    }
  }

  private _configTypeUser() {
    if (this.dataUser.type == this._settings.TYPE_USER.CLIENT) {
      this.typeUser = this._settings.TYPE_USER.CLIENT;
    } else if (this.dataUser.type == this._settings.TYPE_USER.NOT_CLIENT_REGISTER) {
      this.typeUser = this._settings.TYPE_USER.NOT_CLIENT_REGISTER;
    } else if (this.dataUser.type == this._settings.TYPE_USER.NOT_CLIENT_UNREGISTER) {
      this.typeUser = this._settings.TYPE_USER.NOT_CLIENT_UNREGISTER;
    }

    this._storage.setItem('storeType', { type: this.typeUser });

    if (this.typeUser == this._settings.TYPE_USER.NOT_CLIENT_UNREGISTER) {
      this._router.navigate(['/token']);
    }
  }

  private _configInfoUser(): void {
    let infoUser = this.infoUser;

    if (this.getTypeUser() == this._settings.TYPE_USER.NOT_CLIENT_REGISTER) {
      this.infoUser = 'Senha';
    } else {
      this.infoUser = infoUser;
    }
  }

  private _configShowUpdateRegister(): void {
    if (this.getTypeUser() == this._settings.TYPE_USER.NOT_CLIENT_REGISTER) {
      this.showUpdateRegister = true;
    } else {
      this.showUpdateRegister = false;
    }
  }

  private _checkParamsUrl(): void {
    if (!this._checkIfExistStoreUser()) {
      this._activatedRoute.queryParams.subscribe((params: TranslateRequest) => {
        if (Object.keys(params).length) {
          if ('ticket' in params && 'cpf' in params) {
            this._clearFields();

            this.activeFieldCpf = true;

            this._log.print('init check params from external application:');

            this._auth
              .changeKey({
                clientPublicKey: this.localPubKey,
                contextId: this._settings.CONTEXT_ID,
                system: 'PAY',
              })
              .then(
                resultChangeKey => {
                  this._auth.translate(this._configDataTranslate(params, resultChangeKey)).then(
                    resultTranslate => {
                      if ('contents' in resultTranslate) {
                        for (let i = 0; i < resultTranslate.contents.length; i++) {
                          if (resultTranslate.contents[i].key == 'cpf') {
                            this.dataUser.cpf = this.libCrypt.decryptFromApp(
                              resultTranslate.contents[i].value,
                              resultChangeKey.serverPublicKey,
                            );
                          }
                          if (resultTranslate.contents[i].key == 'urlSuccess') {
                            this.dataUser.urlSuccess = this.libCrypt.decryptFromApp(
                              resultTranslate.contents[i].value,
                              resultChangeKey.serverPublicKey,
                            );
                          }
                          if (resultTranslate.contents[i].key == 'urlError') {
                            this.dataUser.urlError = this.libCrypt.decryptFromApp(
                              resultTranslate.contents[i].value,
                              resultChangeKey.serverPublicKey,
                            );
                          }
                          if (resultTranslate.contents[i].key == 'partner') {
                            this.dataUser.partner = this.libCrypt.decryptFromApp(
                              resultTranslate.contents[i].value,
                              resultChangeKey.serverPublicKey,
                            );
                          }
                        }

                        let cpfContextLocal = this.libCrypt
                          .encryptToApp(this.dataUser.cpf, resultChangeKey.serverPublicKey)
                          .toString();

                        this._auth
                          .checkTypeUser({
                            documentNumber: cpfContextLocal,
                            system: 'PAY',
                            channel: '06',
                            ticket: resultTranslate.ticket,
                          })
                          .then(
                            resultCheckTypeUser => {
                              if (resultCheckTypeUser.credentials.indexOf('INTERNET_BANKING') > -1) {
                                this._log.print('client is INTERNET_BANKING');
                                this.dataUser.type = 'INTERNET_BANKING';
                                this.maxlengthPassword = 8;
                              } else {
                                let urlFail = `${
                                  this.dataUser.urlError
                                }?retCode=3&message=O+CPF+informado+n%C3%A3o+pertence+a+um+cliente`;
                                window.top.location.href = urlFail;
                              }
                            },
                            errorCheckTypeUser => {
                              if (errorCheckTypeUser.statusCode == 412) {
                                let urlFail = `${this.dataUser.urlError}?retCode=2&message=Erro+inesperado`;
                                window.top.location.href = urlFail;
                              } else {
                                this._log.print('error from check type user:', errorCheckTypeUser);
                                if (!this.dataUser || !this.dataUser.urlError) {
                                  this._router.navigate(['/login']).then(() => {
                                    this.showLoading = false;
                                    this._router.navigate(['/alert']);
                                  });
                                } else {
                                  this.showLoading = false;
                                  window.top.location.href = this.dataUser.urlError;
                                }
                              }
                            },
                          );
                      }
                    },
                    errorTranslate => {
                      this._log.print('error from translate:', errorTranslate);
                      this._router.navigate(['/login']).then(() => {
                        this.showLoading = false;
                        this._router.navigate(['/alert']);
                      });
                    },
                  );
                },
                errorChangeKey => {
                  this._log.print('error from change key:', errorChangeKey);
                  this._router.navigate(['/login']).then(() => {
                    this.showLoading = false;
                    this._router.navigate(['/alert']);
                  });
                },
              );
          } else {
            if (this.controFirstAccess === true) {
              this._executeProcessWithoutDataParams();
            }
          }
        } else {
          if (this.controFirstAccess === true) {
            this._executeProcessWithoutDataParams();
          }
        }
      });
    } else {
      this.dataUser = this._storage.getItem('storeUser');
      this._configTypeUser();
      this._configInfoUser();
      this._configShowUpdateRegister();
      this.showLoading = false;
    }

    setTimeout(() => {
      this._closeLoading();
    }, 300);
  }

  private _executeProcessWithoutDataParams() {
    this._log.print('init commons process:');
    this.showLoading = false;
    this.dataUser.type = this._settings.TYPE_USER.CLIENT;
    this.typeUser = this._settings.TYPE_USER.CLIENT;
    this._storage.setItem('storeType', { type: this.typeUser });
    this._configInfoUser();
  }

  private _checkIfExistStoreUser(): boolean {
    if (this._storage.getItem('storeUser') === undefined) {
      return false;
    }

    return true;
  }

  private _configDataTranslate(params: TranslateRequest, result: any) {

    let cpf = decodeURI(params.cpf).replace(/ /g, '+');
    let urlSuccess = decodeURI(params.urlSuccess).replace(/ /g, '+');
    let urlError = decodeURI(params.urlError).replace(/ /g, '+');
    let partner = decodeURI(params.partner).replace(/ /g, '+');
    let ticket = decodeURI(params.ticket).replace(/ /g, '+');

    return {
      ticketFrom: {
        id: ticket,
        system: 'PAY',
      },
      ticketTo: {
        id: result.ticket,
        system: 'PAY',
      },
      contents: [
        {
          key: 'cpf',
          value: cpf,
        },
        {
          key: 'urlSuccess',
          value: urlSuccess,
        },
        {
          key: 'urlError',
          value: urlError,
        },
        {
          key: 'partner',
          value: partner,
        },
      ],
      system: 'PAY',
    };
  }

  private _clearFields() {
    this.dataUser = {};
    this._storage.clear();
  }

  private _redirectUrlSucess(urlSuccess: string, resultAuth: any) {
    let path = encodeURI(urlSuccess);
    let tokenEncoded = encodeURIComponent(resultAuth.token);
    let storagecredentials = this._storage.getItem('credentials');
    let credentials = storagecredentials == undefined ? this.credentials : storagecredentials.type;
    let href = path + '?token=' + tokenEncoded + '&typeClient=' + credentials;

    setTimeout(() => {
      window.top.location.href = href;
    }, 300);
  }

  private _checkControlFirstAccess() {
    if (this.controFirstAccess == false) {
      this._storage.clear();
      this.controFirstAccess = true;
    }
  }

  private _storeDataFromAlreadyLDAP(cpf: any) {
    this._auth
      .changeKey({
        clientPublicKey: this.localPubKey,
        contextId: this._settings.CONTEXT_ID,
        system: 'LNC',
      })
      .then(
        resultChangeGetUser => {
          let cpfEncrypted = this.libCrypt.encryptToApp(cpf, resultChangeGetUser.serverPublicKey).toString();

          this._auth
            .getUserByCpf({
              //cpf: cpfEncrypted,
              user: [
                {
                  documentNumber: cpfEncrypted,
                },
              ],
              ticket: resultChangeGetUser.ticket,
              system: 'LNC',
            })
            .then(
              resultGetuser => {
                this.dataUser.name = resultGetuser.name;
                this.dataUser.phone = resultGetuser.phone;

                this._configAll();
                this._configCredentials('NAO_CLIENTE');
              },
              errorGetUser => {
                this._log.print('error from get user:', errorGetUser);
              },
            );
        },
        errorChangeGetUser => {
          this._log.print('error from change key to get user:', errorChangeGetUser);
        },
      );
  }

  private _closeLoading() {
    if (this.showLoading == true) {
      this.showLoading = false;
    }
  }

  private _configAll() {
    this._checkControlFirstAccess();
    this.showLoading = false;

    this._storage.setItem('storeUser', this.dataUser);

    this._configTypeUser();
    this._configInfoUser();
    this._configShowUpdateRegister();
  }
  private _configCredentials(credentials: any) {
    let type = credentials != 'NAO_CLIENTE' ? credentials : 'NAO_CLIENTE';
    this._storage.setItem('credentials', { type: type });
    this.credentials = type;
  }
}
