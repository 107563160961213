<div class="container">
	<div class="row">

		<div class="col-md-offset-2 col-md-4">

			<div class="jwell">
				<h3>Simulador: <span>{{versionAuth}}</span></h3>
				<p>Massa de teste:</p>
				<p>
					CPF CLIENTE: <strong>02558530844</strong> <br> PASS: <strong>258369</strong>
				</p>
				<hr>
				<p>
					CPF NÃO CLIENTE: <strong>06646886830</strong> <br> PASS: <strong>159357</strong>
				</p>
				<hr>
				<div class="form-group">
					<label for="name">Nome:</label>
					<input type="text" name="name" [(ngModel)]="name" class="form-control">
				</div>
				<div class="form-group">
					<label for="cpf">CPF:</label>
					<input type="text" name="cpf" maxlength="11" [(ngModel)]="cpf" class="form-control">
				</div>
				<div class="form-group">
					<label for="phone">Celular:</label>
					<input type="text" name="phone" maxlength="11" [(ngModel)]="phone" class="form-control">
				</div>
				<div class="form-group">
					<label for="name">URL Suceso:</label>
					<input type="text" name="name" [(ngModel)]="urlSuccess" class="form-control">
				</div>
				<div class="form-group">
					<label for="name">URL Erro:</label>
					<input type="text" name="name" [(ngModel)]="urlError" class="form-control">
				</div>

				<div class="form-group">
					<button class="btn btn-danger" (click)="send()">Enviar default</button>
				</div>

				<div class="form-group">
					<button class="btn btn-danger" (click)="sendPf()">Enviar Login PF</button>
				</div>
				<div class="form-group">
					<button class="btn btn-danger" (click)="sendMPf()">Enviar Login MPF</button>
				</div>					
			</div>

		</div>

		<div class="col-md-6">
			<div class="well">
				<h3>Acesso ao painel de autenticação sem passar parametros</h3>
				<hr>
				<a routerLink="/login" class="btn btn-warning btn-block">IR AO LOGIN</a>
				<hr>
				<button (click)="encryptData()" class="btn btn-success btn-block">Clique aqui para encryptar os dados</button> 
				<hr>
				<p>A senha que será encryptada é : <strong>123456</strong></p>
				<hr>
				<pre>
					{{ dataEncrypted | json }}
				</pre>
			</div>
		</div>
	</div>
</div>