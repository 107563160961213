import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SettingsService } from '../services/settings.service';
import { StorageService } from '../services/storage.service';
import { ValidateService } from '../services/validate.service';
import { AuthService } from '../services/auth.service';
import { WindowRef } from '../services/windowRef.service';
import { LogService } from '../services/log.service';

@Component({

    selector: 'update-register',
    templateUrl: './update-register.component.html',
    providers: [ValidateService, AuthService]

})
export class UpdateRegisterComponent implements OnInit {

    showMenu: boolean = false;
    showFooter: boolean = false;
    dataUser: any;
    typeUser: any;
    errorPhone: any = false;
    errorPhrase: any = false;
    errorPhraseConfirmation: any = false;
    libCrypt: any;
    localPubKey: any;
    storeToken: any;
    errorMsg: any = false;
    showLoading: any = false;
    phraseOld: any;
    credentials: any = false;

    constructor(
        private _settings: SettingsService,
        private _storage: StorageService,
        private _validate: ValidateService,
        private _auth: AuthService,
        private _router: Router,
        private _windowRef: WindowRef,
        private _log: LogService
    ) { }

    ngOnInit(): void {
        this._configDataUser();
        this._configTypeUser();
        this._configStoreToken();
        this._configCredentials();
        this._configphraseOld();

        this.libCrypt = this._windowRef.nativeWindow.libdlecc;
        this.localPubKey = this.libCrypt.init();

    }

    onCancel() {

        this.dataUser.phone = '';
        this.dataUser.phrase = '';
        this.dataUser.phraseConfirmation = '';
    }

    onFocusPhone() {
        this.errorPhone = false;
    }

    onFocusPassword() {
        this.errorPhrase = false;
    }

    onFocusPasswordConfirmation() {
        this.errorPhraseConfirmation = false;
    }

    onUpdate(data: any) { 

        if (!this._validate.checkPhone(data.phone)) {
            this.errorPhone = this._settings.ERROR_MESSAGES.PHONE;
            return false;
        }

        if(('phrase' in data) == false ){

            this.errorPhrase = this._settings.ERROR_MESSAGES.PHRASE;
            return false;
        }

        if (data.phrase == null) {
            data.phrase = this.phraseOld;
        }

        if (!this._validate.checkPassword(data.phrase)) {
            this.errorPhrase = this._settings.ERROR_MESSAGES.PHRASE;
            return false;
        }
        if (data.phrase != this.phraseOld) {
            if (!this._validate.checkPasswordConfirmation(data.phrase, data.phraseConfirmation)) {
                this.errorPhraseConfirmation = this._settings.ERROR_MESSAGES.PHRASE_CONFIRMATION;
                return false;
            }
        }

        this.errorPhone = false;
        this.errorPhrase = false;
        this.errorPhraseConfirmation = false;
        this.showLoading = true;

        let tokenAuthenticate = this.storeToken != undefined ? this.storeToken.token : false;


        this._auth.changeKey({
            clientPublicKey: this.localPubKey,
            contextId: this._settings.CONTEXT_ID,
            system: 'LNC'
        }).then(
            resultChangeKey => {
                let cpfEncrypted = this.libCrypt.encryptToApp(data.cpf, resultChangeKey.serverPublicKey).toString();
                let nameEncrypted = this.libCrypt.encryptToApp(data.name, resultChangeKey.serverPublicKey).toString();
                let phoneEncrypted = this.libCrypt.encryptToApp(data.phone, resultChangeKey.serverPublicKey).toString();

                this._auth.updateRegister({
                    documentNumber: cpfEncrypted,
                    name: nameEncrypted,
                    phone: phoneEncrypted,
                    system: 'LNC',
                    ticket: resultChangeKey.ticket
                }).then(

                    resultUpdateRegister => {
                        if (data.phrase !== this.phraseOld) {

                            this._auth.changeKey({
                                clientPublicKey: this.localPubKey,
                                contextId: this._settings.CONTEXT_ID,
                                system: 'LNC'
                            }).then(
                                resultNewChangeKey => {
                                    let cpfEncrypted = this.libCrypt.encryptToApp(data.cpf, resultNewChangeKey.serverPublicKey).toString();
                                    let phraseEncrypted = this.libCrypt.encryptToApp(data.phrase, resultNewChangeKey.serverPublicKey).toString();

                                    this._auth.changePassword({
                                        documentNumber: cpfEncrypted,
                                        password: phraseEncrypted,
                                        system: 'LNC',
                                        ticket: resultNewChangeKey.ticket
                                    }).then(
                                        resultChangePassword => {
                                            this._redirectSuccess();
                                        },
                                        errorUptadeRegister => {
                                            this._log.print("error from change password:", errorUptadeRegister);
                                            this.showLoading = false;
                                            this.errorMsg = errorUptadeRegister.message;
                                        }
                                    );
                                },
                                errorNewChangeKey => {
                                    this._log.print("error from new change key:", errorNewChangeKey);
                                    this.showLoading = false;
                                    this.errorMsg = this._settings.ERROR_MESSAGES.GENERIC;
                                }
                            );
                        } else {
                            this.showLoading = false;
                            this.errorMsg = 'A nova senha não pode ser igual a antiga';
                        }
                    },
                    erroUpdateRegister => {
                        this._log.print("error from modify user:", erroUpdateRegister);
                        this.showLoading = false;
                        this.errorMsg = erroUpdateRegister.message;
                    }
                );
            },
            errorChangeKey => {
                this._log.print("error from change key:", errorChangeKey);
                this.showLoading = false;
                this.errorMsg = this._settings.ERROR_MESSAGES.GENERIC;
            }
        );
    }

    public _configphraseOld() {
        this.dataUser = this._storage.getItem('storeUser');
        let phraseOld = this.dataUser.phrase;
        this.phraseOld = phraseOld;
        this.dataUser.phrase = '';
    }

    public _configDataUser() {
        this.dataUser = this._storage.getItem('storeUser');
    }

    public _configTypeUser() {
        this.typeUser = this._storage.getItem('storeType');
    }

    public _configStoreToken() {
        this.storeToken = this._storage.getItem('storeToken');
    }

    public _redirectSuccess() {
        this._router.navigate(['/update-register']).then(() => {
            this._router.navigate(['/login']).then(() => {
                let urlSuccess = this.dataUser != undefined ? this.dataUser.urlSuccess : false;
                let token = this.storeToken != undefined ? this.storeToken.token : false;
                this._clearFields();
                if (urlSuccess && token) {
                    let path = encodeURI(urlSuccess);
                    let tokenEncoded = encodeURIComponent(token);
                    let credentials = this.credentials == false? 'NAO_CLIENTE': this.credentials;
                    let href = path + "?token=" + tokenEncoded + "&typeClient=" + credentials;
                    window.top.location.href = href;
                } else {
                    this.showLoading = false;
                    this._router.navigate(['/alert']);
                }
            });
        });
    }

    public _clearFields() {
        this.dataUser = {};
        this._storage.clear();
    }
    
    public _configCredentials() {
        let credentials = this._storage.getItem('credentials');
        this.credentials = credentials.type;
    }
}



