import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { SettingsService } from '../services/settings.service';
import { StorageService }  from '../services/storage.service';
import { AuthService } from '../services/auth.service';
import { WindowRef } from '../services/windowRef.service';
import { LogService } from '../services/log.service';

import { UcwordPipe } from '../pipes/ucword.pipe';
import { PhoneFakePipe } from '../pipes/phone-fake.pipe';
import { StripNamePipe } from '../pipes/strip-name.pipe';
import { LimitCaracterePipe } from '../pipes/limit-caractere.pipe';
import { PhoneProtectedPipe } from '../pipes/phone-protected.pipe';

@Component({
  selector: 'token',
  templateUrl: './token.component.html',
  providers: [UcwordPipe, PhoneFakePipe, StripNamePipe, AuthService, LimitCaracterePipe ,PhoneProtectedPipe]

})
export class TokenComponent implements OnInit {

  showMenu: boolean = false;
  showFooter: boolean = false;
  dataUser: any;
  typeUser: any;
  contentTitle: any;
  contentInfo: any;
  libCrypt: any;
  localPubKey: any;
  showLoading: boolean = false;
  errorMsg: any = false;
  teste: string = 'hugo araujo machado';

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _location: Location,
    private _settings: SettingsService,
    private _storage: StorageService,
    private _auth: AuthService,
    private _ucword: UcwordPipe,
    private _phoneFake: PhoneFakePipe,
    private _windowRef: WindowRef,
    private _stripname: StripNamePipe,
    private _log: LogService,
    private _limitCaractere: LimitCaracterePipe,
    private _phoneProtected: PhoneProtectedPipe
  ) { }

  ngOnInit(): void {

    this._configDataUser();
    this._configTypeUser();
    this._configContent();
    this.libCrypt = this._windowRef.nativeWindow.libdlecc;
    this.localPubKey = this.libCrypt.init();
  }

  goBack(): void {
    this._router.navigate(['/token']).then(() => {
      window.top.location.href = this.dataUser.urlError;
    });
  }

  generateTokenToSend() : void {
    this.showLoading = true;
    let storeUser = this._storage.getItem('storeUser');
    if(storeUser != null || storeUser != undefined) {

      this._auth.changeKey({
        clientPublicKey: this.localPubKey,
        contextId: this._settings.CONTEXT_ID,
        system: 'MIA'
      }).then ( resultChangeKey => {

        let storeUser = this._storage.getItem('storeUser');
          if(storeUser != null || storeUser != undefined) {
            let phoneData = storeUser.phone;
            let ddd = phoneData.slice(0, 2);
            let phone = phoneData.slice(2, phoneData.length);
            let date = new Date();
            let horario = `${date.getMilliseconds()}${date.getHours()}MIA${date.getMinutes()}909992`
            this._storage.setItem('referOper', horario);

              if(this.typeUser.type == this._settings.TYPE_USER.NOT_CLIENT_UNREGISTER) {

                let objectToSend = {
                  "ticket": resultChangeKey.ticket,
                  "systemCode": "MIA",
                  "ddd": this.libCrypt.encryptToApp(ddd, resultChangeKey.serverPublicKey).toString(),
                  "phone": this.libCrypt.encryptToApp(phone, resultChangeKey.serverPublicKey).toString(),
                  "stimulumId": this.libCrypt.encryptToApp('MIASS04968', resultChangeKey.serverPublicKey).toString(),
                  "documentNumber": this.libCrypt.encryptToApp(storeUser.cpf,  resultChangeKey.serverPublicKey),
                  "personType": this.libCrypt.encryptToApp('F', resultChangeKey.serverPublicKey).toString(),
                  "channelId": this.libCrypt.encryptToApp('62', resultChangeKey.serverPublicKey).toString(),
                  "referOper": this.libCrypt.encryptToApp(horario, resultChangeKey.serverPublicKey).toString(),
                }

                this._auth.generateToken(objectToSend).then(res => {

                  this.showLoading = false;
                  if (this.typeUser.type == this._settings.TYPE_USER.NOT_CLIENT_UNREGISTER) {
                    this._router.navigate(['/add-register']);
                  } else {
                    this._router.navigate(['/new-password']);
                  }

                }, errorGerateToken => {
                  this._log.print("error from send sms token:", errorGerateToken);
                  this.showLoading = false;
                  this.errorMsg = this._settings.ERROR_MESSAGES.GENERIC;
                })
              }
              else {
                let objectToSend = {
                    "ticket": resultChangeKey.ticket,
                    "systemCode": "MIA",
                    "ddd": this.libCrypt.encryptToApp(ddd, resultChangeKey.serverPublicKey).toString(),
                    "phone": this.libCrypt.encryptToApp(phone, resultChangeKey.serverPublicKey).toString(),
                    "stimulumId": this.libCrypt.encryptToApp('MIASS04969', resultChangeKey.serverPublicKey).toString(),
                    "documentNumber": this.libCrypt.encryptToApp(storeUser.cpf,  resultChangeKey.serverPublicKey),
                    "personType": this.libCrypt.encryptToApp('F', resultChangeKey.serverPublicKey).toString(),
                    "channelId": this.libCrypt.encryptToApp('62', resultChangeKey.serverPublicKey).toString(),
                    "referOper": this.libCrypt.encryptToApp(horario, resultChangeKey.serverPublicKey).toString(),
                  }
  
                  this._auth.generateToken(objectToSend).then(res => {

                    this.showLoading = false;
                    if (this.typeUser.type == this._settings.TYPE_USER.NOT_CLIENT_UNREGISTER) {
                      this._router.navigate(['/add-register']);
                    } else {
                      this._router.navigate(['/new-password']);
                    }
  
                  }, errorGerateToken => {
                    this._log.print("error from send sms token:", errorGerateToken);
                    this.showLoading = false;
                    this.errorMsg = this._settings.ERROR_MESSAGES.GENERIC;
                  })
                            
              }

          }
        })
      }
    }
    
    public _configDataUser() {

        if( this._storage.getItem('storeUser') != undefined )
        {
            this.dataUser = this._storage.getItem('storeUser');
        }
        else{
            this._router.navigate(['/']);
        }
    }

    public _configTypeUser() {

        if( this._storage.getItem('storeType') != undefined )
        {
            this.typeUser = this._storage.getItem('storeType');
        }
        else{
            this._router.navigate(['/']);
        }
    }

    public _configContent(): void {

        if( this.typeUser == undefined ){
            this._router.navigate(['/']);
        }
        else{

            if (this.typeUser.type == this._settings.TYPE_USER.NOT_CLIENT_UNREGISTER) {

                this.contentTitle = `
    									<h4>Olá, <strong>${  this._ucword.transform(this._stripname.transform(this.dataUser.name))}.</strong></h4>
    			           				<p>Seja bem vindo (a) ao módulo de segurança Santander.</p>
    								`;

                this.contentInfo = `	</br>
    									<p>Para finalizar seu cadastro enviaremos um Código SMS Token para o seu celular:</p>
    									<p>&nbsp;&nbsp;<strong>${this._phoneProtected.transform(this.dataUser.phone)}</strong></p>
    					                <p>Clique no botão abaixo para enviar o código.</p></br>
    								`;
            } else {

                this.contentTitle = `<h4><strong>Esqueceu sua senha?</strong></h4>`;
                this.contentInfo = `	<br>
    									<p>Enviaremos um Código SMS Token para o seu celular:</p>
    									<p>&nbsp;&nbsp;<strong>${this._phoneProtected.transform(this.dataUser.phone)}</strong></p>
    									<p>para que seja cadastrada uma nova senha de acesso.</p>
    					                <p>Clique no botão abaixo para enviar o código.</p></br>
    								`;
            }
        }
    }

    public _createDataToSendToken(token: string, typeUserNoRegister: any) : any {
        let storeUser = this._storage.getItem('storeUser');
        if(storeUser != null || storeUser != undefined) {

            let phoneData = storeUser.phone;
            let ddd = phoneData.slice(0, 2);
            let phone = phoneData.slice(2, phoneData.length);
            if(this.typeUser.type == typeUserNoRegister) {
                let resultData = {
                    "variables": [
                        {
                            "key": "4",
                            "value": ddd
                        },
                        {
                            "key":"5",
                            "value": phone
                        },
                        {
                            "key": "token",
                            "value": token 
                        }
                    ],
                    "systemId": "MIA",
                    "stimulumId": "MIASS04968"
                }
                return resultData;
            } else {
                let resultData = {
                    "variables": [
                        {
                            "key": "4",
                            "value": ddd
                        },
                        {
                            "key":"5",
                            "value": phone
                        },
                        {
                            "key": "token",
                            "value": token
                        }
                    ],
                    "systemId": "MIA",
                    "stimulumId": "MIASS04969"
                }
                return resultData;
            }
            
        }   
    }

    public _dataToSendToken(result: any): any {

        let storeUser = this._storage.getItem('storeUser');

        if (storeUser != undefined) {

            let cpf = storeUser.cpf ;
            let name = this._limitCaractere.transform(storeUser.name,10);
            let celular = storeUser.phone;
            let ddd = celular.slice(0, 2);
            let phone = celular.slice(2, celular.length);
            let message: string;
            let proposalId: string = cpf;

            if (this.typeUser.type == this._settings.TYPE_USER.NOT_CLIENT_UNREGISTER) {
                message = "Santander SMS Token. Para finalizar seu cadastro no Modulo Seguranca Santander use codigo [TOKEN]";
            } else {
                message = "Santander SMS Token. Para definir uma nova senha no Modulo Seguranca Santander use codigo [TOKEN]";
            }

            let nameSend  = 'DLB AUTENTIC';
            let cpfEncrypted = this.libCrypt.encryptToApp(cpf, result.serverPublicKey).toString();
            let nameEncrypted = this.libCrypt.encryptToApp(nameSend, result.serverPublicKey).toString();
            let dddEncrypted = this.libCrypt.encryptToApp(ddd, result.serverPublicKey).toString();
            let phoneEncrypted = this.libCrypt.encryptToApp(phone, result.serverPublicKey).toString();
            let messageEncrypted = this.libCrypt.encryptToApp(message, result.serverPublicKey).toString();
            let proposalIdEncrypted = this.libCrypt.encryptToApp(proposalId,result.serverPublicKey).toString();
            let systemCode = '0000000000000000000';

            return {

                ticket: result.ticket,
                channel: "62",
                personType: "F",
                documentNumber: cpfEncrypted,
                proposalId: proposalIdEncrypted,
                system: "MIA",
                systemCode: systemCode,
                senderName: nameEncrypted,
                phone: {
                    number: phoneEncrypted,
                    ddd: dddEncrypted
                },
                message: messageEncrypted
            };
        }

        return { ticket: result.ticket };

    }
}




