<!-- header component -->
<menu-component *ngIf="showMenu"></menu-component>
<!-- header component end -->

<div class="home-login-component" *ngIf="dataUser">
  <div class="container">
    <div class="error-generic text-center loading" *ngIf="showLoading"><img src="../../../assets/images/rolling.gif" alt="aguarde" width="70"><br/><br/>
      <p>Aguarde</p>
    </div>

    <div class="card card-container" *ngIf="!showLoading">
      <div class="row">
        <div class="col-md-12 col-xs-12">
          <div class="data-usu">
            <h4><strong>Criar nova senha</strong></h4>
            <p>Preencha o campo abaixo com código SMS Token enviado para o seu celular e informe a nova senha.
            </p>
            <p>O SMS Token foi enviado para o celular: <strong>{{ dataUser.phone | phoneprotected }}</strong></p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
      <form class="form-signin" novalidate autocomplete="off" method="POST"  (submit)="saveNewPassword(dataUser)">
        <div class="form-group">
          <label class="label-down">CPF</label>
          <p>{{ dataUser.cpf | cpffake }}</p>
        </div>
        <br>

        <div class="form-group">
          <label class="label-down">Código SMS Token</label>
          <input (focus)="onFocusToken()" type="text" name="smsToken" [(ngModel)]="dataUser.token" class="form-control">
          <p class="help-block" *ngIf="errorToken">
            <span style="color: red;">{{ errorToken }}</span>
          </p>
        </div>
        <br>

        <div class="form-group">
          <label class="label-down" for="phrase">Nova senha &nbsp;(6 números)
            <!-- <input type="password" name="password"  maxlength="6" class="form-control" id="password" [(ngModel)]="dataUser.password" (focus)="onFocusPassword()"> -->
            <input type="password" name="phrase"  maxlength="6" class="form-control" id="phrase" [(ngModel)]="dataUser.phrase" (focus)="onFocusPassword()">
          </label>
          <p class="help-block" *ngIf="errorPharse">
            <span style="color: red;">{{ errorPharse }}</span>
          </p>
        </div>
        <br>

        <div class="form-group">
          <label class="label-down" for="phraseConfirmation">Confirmar senha
           <!-- <input  type="password" name="passwordConfirmation" maxlength="6" class="form-control" id="passwordConfirmation" [(ngModel)]="dataUser.passwordConfirmation" (focus)="onFocusPasswordConfirmation()"> -->
           <input  type="password" name="phraseConfirmation" maxlength="6" class="form-control" id="phraseConfirmation" [(ngModel)]="dataUser.phraseConfirmation" (focus)="onFocusPasswordConfirmation()">
          </label>
          <p class="help-block" *ngIf="errorPharseConfirmation">
            <span style="color: red;">{{ errorPharseConfirmation }}</span>
          </p>
          <p class="help-block" *ngIf="errorMsg">
            <span style="color: red;">{{ errorMsg }}</span>
          </p>
        </div>
        <br>

        <button  class="btn btn-lg btn-primary btn-block btn-signin" type="submit">CONFIRMAR</button>
      </form>
      <!-- /form -->

      <div class="row">
        <br>
        <div class="col-md-12 col-xs-12 text-center">
          <a href="javascript:void(0);" routerLink="/token" class="forgot-password">
            <em class="glyphicon glyphicon-ok-sign"></em> Reenviar SMS Token
          </a>
        </div>
        <!--
        <div class="col-md-5 col-xs-6">
          <a class="forgot-password" (click)="goBack()">
            <em class="glyphicon glyphicon-earphone"></em> Editar o celular
          </a>
        </div>
        -->
      </div>

    </div>
    <!-- /card-container -->
  </div>
  <!-- /container -->
</div>


<!-- footer -->
<footer-component *ngIf="showFooter"></footer-component>
<!-- footer end -->
