import { Pipe, PipeTransform } from '@angular/core';

@Pipe({

	name: 'ucword'
})
export class UcwordPipe implements PipeTransform {

	transform(value: string) {

		if(value ===undefined || !value.length) return value;
		
		return (value + '')
			.replace(/^([a-z\u00E0-\u00FC])|\s+([a-z\u00E0-\u00FC])/g, ($1) => {
				return $1.toUpperCase();
			});
	}
}
