import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { Location } from '@angular/common';

import { SettingsService } from '../services/settings.service';
import { StorageService } from '../services/storage.service';
import { ValidateService } from '../services/validate.service';
import { WindowRef } from '../services/windowRef.service';
import { AuthService } from '../services/auth.service';
import { LogService } from '../services/log.service';

import { DataInputs } from '../contracts/data-inputs.interface';

declare let DLECC: any;

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  providers: [ValidateService, AuthService],
})

export class LoginComponent implements OnInit {
  showMenu: boolean = false;
  showFooter: boolean = false;
  showRemember: boolean = false;
  showUpdateRegister: boolean = false;
  infoUser: string = 'Senha do internet banking';
  dataUser: any = { cpf: '', phrase: '' };
  typeUser: any = 1;
  errorCpf: any = false;
  errorPhrase: any = false;
  linkForgotPhrase: any;
  libCrypt: any;
  localPubKey: any;
  showLoading: boolean = true;
  errorAuth: any = false;
  activeFieldCpf: boolean = false;
  controFirstAccess: boolean = false;
  maxlengthPhrase: any = 6;
  credentials: any = false;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _location: Location,
    private _settings: SettingsService,
    private _storage: StorageService,
    private _validate: ValidateService,
    private _windowRef: WindowRef,
    private _auth: AuthService,
    private _log: LogService,
  ) {}

  ngOnInit(): void {
    this.libCrypt = this._windowRef.nativeWindow.libdlecc;
    this.localPubKey = this.libCrypt.init();
    this._checkParamsUrl();
    this.linkForgotPhrase = this._settings.LINK_FORGOT_PHRASE;
  }


  signin(data: any) {
    let cpf = data.cpf
    cpf = cpf.replace('.', '');
    cpf = cpf.replace('.', '');
    cpf = cpf.replace('-', '');
    
    if (!this._validate.checkCpf(cpf)) {
      this.errorCpf = this._settings.ERROR_MESSAGES.CPF;
      return false;
    }

    if ('phrase' in data == false) {
      this.errorPhrase = this._settings.ERROR_MESSAGES.PHRASE;
      return false;
    } 

    if (!this._validate.checkPasswordWithAlpha(data.phrase)) {
      this.errorPhrase = this._settings.ERROR_MESSAGES.PHRASE;
      return false;
    }

    this.errorCpf = false;
    this.errorPhrase = false;
    this.showLoading = true;

    this._auth
      .changeKey({
        clientPublicKey: this.localPubKey,
        contextId: this._settings.CONTEXT_ID,
        system: 'PAY',
      })
      .then(
        resultChangeKey => {

          let cpfEncrypted = this.libCrypt.encryptToApp(data.cpf, resultChangeKey.serverPublicKey).toString();
          let phrasedEncrypted = this.libCrypt.encryptToMF(data.phrase, resultChangeKey.serverPublicKey).toString();

          this._auth
            .checkTypeUser({
              documentNumber: cpfEncrypted,
              system: 'PAY',
              ticket: resultChangeKey.ticket,
              channel: '06',
            })
            .then(
              resultTypeUser => {

                if (resultTypeUser.credentials.length == 0) {
                  this.showLoading = false;
                  this.errorAuth = this._settings.ERROR_MESSAGES.NOT_CLIENT;
                } else if (resultTypeUser.credentials[0] == 'NAO_CLIENTE' || resultTypeUser.credentials.indexOf('INTERNET_BANKING') < 0) {
                  this._log.print('init authenticate process to user type (2):');
                  this._auth
                    .authenticate({
                      documentNumber: cpfEncrypted,
                      password: phrasedEncrypted,
                      credential: 'NAO_CLIENTE',
                      system: 'PAY',
                      ticket: resultTypeUser.ticket,
                    })
                    .then(
                      resultAuth => {

                        let urlSuccess =
                          this._storage.getItem('storeUser') != undefined
                            ? this._storage.getItem('storeUser').urlSuccess
                            : false;
                        this._clearFields();
                        if (urlSuccess && resultAuth.token) {
                          this._redirectUrlSucess(urlSuccess, resultAuth);
                        } else {

                          this._router.navigate(['/login']).then(() => {
                            this.showLoading = false;
                            this._router.navigate(['/alert']);
                          });
                        }
                      },
                      errorAuth => {
                        this._log.print('error from auth:', errorAuth);

                        this.showLoading = false;

                        if (errorAuth.status == 401) {
                          if (errorAuth.error.statusCode == -60) {
                            this.errorAuth = this._settings.ERROR_MESSAGES.ACTION_REQUIRED;
                          } else {
                            this.errorAuth = this._settings.ERROR_MESSAGES.USER_OR_PHRASE_INVALID;
                          }
                        } else {
                          this._clearFields();
                          if (!this.dataUser || !this.dataUser.urlError) {
                            this._router.navigate(['/login']).then(() => {
                              this._router.navigate(['/alert']);
                            });
                          } else {
                            window.top.location.href = this.dataUser.urlError;
                          }
                        }
                      },
                    );
                } else {
                  this._log.print('init authenticate process to user type (1):');
                  this._auth
                    .authenticateClient({
                      documentNumber: cpfEncrypted,
                      password: phrasedEncrypted,
                      credential: resultTypeUser.credentials[0],
                      system: 'PAY',
                      ticket: resultTypeUser.ticket,
                    })
                    .then(
                      resultAuth => {

                        let urlSuccess = this._storage.getItem('storeUser') != undefined
                        ? this._storage.getItem('storeUser').urlSuccess : false;
                        this._clearFields();

                        if (urlSuccess && resultAuth.token) {
                          this._redirectUrlSucess(urlSuccess, resultAuth);
                        } else {

                          this._router.navigate(['/login']).then(() => {
                            this.showLoading = false;
                            this._router.navigate(['/alert']);
                          });
                        }
                      },
                      errorAuth => {
                        this._log.print('error from auth:', errorAuth);

                        this.showLoading = false;

                        if (errorAuth.status == 401) {
                          if (errorAuth.error.statusCode == -60) {
                            this.errorAuth = this._settings.ERROR_MESSAGES.ACTION_REQUIRED;
                          } else {
                            this.errorAuth = this._settings.ERROR_MESSAGES.USER_OR_PHRASE_INVALID;
                          }
                        } else {
                          this._clearFields();
                          if (!this.dataUser || !this.dataUser.urlError) {
                            this._router.navigate(['/login']).then(() => {
                              this._router.navigate(['/alert']);
                            });
                          } else {                            
                            window.top.location.href = this.dataUser.urlError;
                          }
                        }
                      },
                    );
                }
              },
              errorTypeUser => {
                this._log.print('error from type user:', errorTypeUser);
                this.showLoading = false;

                if (errorTypeUser.statusCode == 412) {
                  this.errorAuth = this._settings.ERROR_MESSAGES.NOT_CLIENT;
                } else {
                  this.errorAuth = this._settings.ERROR_MESSAGES.AUTH;
                }
              },
            );
        },
        errorChangeKey => {
          this._log.print('error from change key:', errorChangeKey);

          this.showLoading = false;
          if (!this.dataUser || !this.dataUser.urlError) {
            this._router.navigate(['/login']).then(() => {
              this._router.navigate(['/alert']);
            });
          } else {
            window.top.location.href = this.dataUser.urlError;
          }
        },
      );
  }

  goBack() {
    this._router.navigate(['/login']).then(() => {
      window.top.location.href = this.dataUser.urlError;
    });
  }

  onFocusCpf(): void {
    this.errorCpf = false;
    this.errorAuth = false;
  }

  onFocusPassword(): void {
    this.errorPhrase = false;
    this.errorAuth = false;
  }

  goToToken(): any {
    if (this.getTypeUser() == this._settings.TYPE_USER.NOT_CLIENT_REGISTER) {
      this._router.navigate(['/login']).then(() => {
        this._router.navigate(['/token']);
      });
      return false;
    }
  }

  goToUpdateRegister(data: any) {
    if (!this._validate.checkCpf(data.cpf)) {
      this.errorCpf = this._settings.ERROR_MESSAGES.CPF;
      return false;
    }

    if ('phrase' in data == false) {
      this.errorPhrase = this._settings.ERROR_MESSAGES.PHRASE;
      return false;
    }

    if (!this._validate.checkPassword(data.phrase)) {
      this.errorPhrase = this._settings.ERROR_MESSAGES.PHRASE;
      return false;
    }

    this.errorCpf = false;
    this.errorPhrase = false;
    this.showLoading = true;

    this._auth
      .changeKey({
        clientPublicKey: this.localPubKey,
        contextId: this._settings.CONTEXT_ID,
        system: 'PAY',
      })
      .then(
        resultChangeKey => {
          
          let cpf = this.libCrypt.encryptToApp(data.cpf, resultChangeKey.serverPublicKey).toString();
          let phrase = this.libCrypt.encryptToMF(data.phrase, resultChangeKey.serverPublicKey).toString();

          this._auth
            .authenticate({
              documentNumber: cpf,
              password: phrase,
              credential: 'NAO_CLIENTE',
              system: 'PAY',
              ticket: resultChangeKey.ticket,
            })
            .then(
              resultAuth => {
                this._storage.setItem('storeUser', data);
                this._storage.setItem('storeToken', { token: resultAuth.token });
                
                this._router.navigate(['/login']).then(x => {
                  this._router.navigate(['/update-register']);
                })
              },
              errorAuth => {
                this._log.print('error from auth:', errorAuth);

                this.showLoading = false;

                if (errorAuth.httpStatus == 401) {
                  if (errorAuth.statusCode == -60) {
                    this.errorAuth = this._settings.ERROR_MESSAGES.ACTION_REQUIRED;
                  } else {
                    this.errorAuth = this._settings.ERROR_MESSAGES.USER_OR_PHRASE_INVALID;
                  }
                } else {
                  this._clearFields();
                  if (!this.dataUser || !this.dataUser.urlError) {
                    this._router.navigate(['/login']).then(() => {
                      this._router.navigate(['/alert']);
                    });
                  } else {
                    window.top.location.href = this.dataUser.urlError;
                  }
                }
              },
            );
        },
        errorChangeKey => {
          this._log.print('error from change key:', errorChangeKey);
          this.showLoading = false;
          this.errorAuth = errorChangeKey.message;
        },
      );
  }

  getTypeUser() {
    return this.typeUser;
  }

  configTooltip(event: any) {
    let el: any = event.target.nextElementSibling;

    if (el.getAttribute('data-tooltip-custom') == null) {
      el.style.display = 'block';
      el.setAttribute('data-tooltip-custom', true);
    } else {
      el.style.display = 'none';
      el.removeAttribute('data-tooltip-custom');
    }
  }

  public _configTypeUser() {
    if (this.dataUser.type == this._settings.TYPE_USER.CLIENT) {
      this.typeUser = this._settings.TYPE_USER.CLIENT;
    } else if (this.dataUser.type == this._settings.TYPE_USER.NOT_CLIENT_REGISTER) {
      this.typeUser = this._settings.TYPE_USER.NOT_CLIENT_REGISTER;
    } else if (this.dataUser.type == this._settings.TYPE_USER.NOT_CLIENT_UNREGISTER) {
      this.typeUser = this._settings.TYPE_USER.NOT_CLIENT_UNREGISTER;
    }

    this._storage.setItem('storeType', { type: this.typeUser });

    if (this.typeUser == this._settings.TYPE_USER.NOT_CLIENT_UNREGISTER) {
      this._router.navigate(['/token']);
    }
  }

  public _configInfoUser(): void {
    let infoUser = this.infoUser;

    if (this.getTypeUser() == this._settings.TYPE_USER.NOT_CLIENT_REGISTER) {
      this.infoUser = 'Senha';
    } else {
      this.infoUser = infoUser;
    }
  }

  public _configShowUpdateRegister(): void {
    if (this.getTypeUser() == this._settings.TYPE_USER.NOT_CLIENT_REGISTER) {
      this.showUpdateRegister = true;
    } else {
      this.showUpdateRegister = false;
    }
  }

  public _checkParamsUrl(): void {
    if (!this._checkIfExistStoreUser()) {
      this._activatedRoute.queryParams.subscribe((params: DataInputs) => {
        if (Object.keys(params).length) {
          if ('ticket' in params && 'cpf' in params) {
            this._clearFields();
            this.activeFieldCpf = true;
            this._log.print('init check params from external application:');

            this._auth.changeKey({
                clientPublicKey: this.localPubKey,
                contextId: this._settings.CONTEXT_ID,
                system: 'PAY',
              })
              .then(
                resultChangeKey => {
                  let translateData = this._configDataTranslate(params, resultChangeKey);
                  this._auth.translate(translateData).then(
                    resultTranslate => {

                      if ('contents' in resultTranslate) {
                        for (let i = 0; i < resultTranslate.contents.length; i++) {
                          if (resultTranslate.contents[i].key == 'cpf') {
                            this.dataUser.cpf = this.libCrypt.decryptFromApp(
                              resultTranslate.contents[i].value,
                              resultChangeKey.serverPublicKey,
                            );
                            // this.formataCPF(this.dataUser.cpf);
                            // console.log(this.dataUser.cpf);
                          }
                          if (resultTranslate.contents[i].key == 'name') {
                            this.dataUser.name = this.libCrypt.decryptFromApp(
                              resultTranslate.contents[i].value,
                              resultChangeKey.serverPublicKey,
                            );
                          }
                          if (resultTranslate.contents[i].key == 'phone') {
                            this.dataUser.phone = this.libCrypt.decryptFromApp(
                              resultTranslate.contents[i].value,
                              resultChangeKey.serverPublicKey,
                            );
                          }
                          if (resultTranslate.contents[i].key == 'urlSuccess') {
                            this.dataUser.urlSuccess = this.libCrypt.decryptFromApp(
                              resultTranslate.contents[i].value,
                              resultChangeKey.serverPublicKey,
                            );
                          }
                          if (resultTranslate.contents[i].key == 'urlError') {
                            this.dataUser.urlError = this.libCrypt.decryptFromApp(
                              resultTranslate.contents[i].value,
                              resultChangeKey.serverPublicKey,
                            );
                          }
                          if (resultTranslate.contents[i].key == 'partner') {
                            this.dataUser.partner = this.libCrypt.decryptFromApp(
                              resultTranslate.contents[i].value,
                              resultChangeKey.serverPublicKey,
                            );
                          }
                        }

                        let cpfContextLocal = this.libCrypt.encryptToApp(this.dataUser.cpf, resultChangeKey.serverPublicKey).toString();

                        this._auth
                          .checkTypeUser({
                            documentNumber: cpfContextLocal,
                            system: 'PAY',
                            channel: '06',
                            ticket: resultTranslate.ticket,
                          })
                          .then(
                            resultCheckTypeUser => {

                              if (resultCheckTypeUser.credentials.length == 0) {
                                this.dataUser.type = this._settings.TYPE_USER.NOT_CLIENT_UNREGISTER;
                                this.maxlengthPhrase = 6;
                                this._configAll();
                                this._configCredentials('NAO_CLIENTE');
                              } else if (resultCheckTypeUser.credentials[0] == 'NAO_CLIENTE') {
                                this.dataUser.type = this._settings.TYPE_USER.NOT_CLIENT_REGISTER;
                                this.maxlengthPhrase = 6;
                                this._storeDataFromAlreadyLDAP(this.dataUser.cpf);
                              } else if (resultCheckTypeUser.credentials.indexOf('INTERNET_BANKING') > -1){
                                this.dataUser.type = this._settings.TYPE_USER.CLIENT;
                                this.maxlengthPhrase = 8;
                                this._configAll();
                                this._configCredentials(resultCheckTypeUser.credentials[0]);
                              } else {                             
                                this._auth
                                .changeKey({
                                  clientPublicKey: this.localPubKey,
                                  contextId: this._settings.CONTEXT_ID,
                                  system: 'LNC',
                                })
                                .then(
                                  resultChangeGetUser => {

                                    let cpfEncrypted = this.libCrypt.encryptToApp(this.dataUser.cpf, resultChangeGetUser.serverPublicKey).toString();
                                    
                                    this._auth
                                      .getUserByCpf({
                                        documentNumber: cpfEncrypted,                                
                                        ticket: resultChangeGetUser.ticket,
                                        system: 'LNC',
                                      })
                                      .then(
                                        resultGetuser => {
                                          this.dataUser.type = this._settings.TYPE_USER.NOT_CLIENT_REGISTER;
                                          this.maxlengthPhrase = 6;
                                          this._storeDataFromAlreadyLDAP(this.dataUser.cpf);
                                        },
                                        errorGetUser => {
                                          if (errorGetUser.exceptionCode == 40201001){
                                            this._log.print('user not found:', errorGetUser);
                                            this.dataUser.type = this._settings.TYPE_USER.NOT_CLIENT_UNREGISTER;
                                            this.maxlengthPhrase = 6;
                                            this._configAll();
                                            this._configCredentials('NAO_CLIENTE');
                                          }
                                          else {
                                            this._log.print('error from get user:', errorGetUser);
                                            console.log('aqui')
                                            // window.top.location.href = this.dataUser.urlError;
                                          }
                                        },
                                      );
                                  },
                                  errorChangeGetUser => {
                                    this._log.print('error from change key to get user:', errorChangeGetUser);
                                  },
                                );

                              }
                            },
                            errorCheckTypeUser => {
                              if (errorCheckTypeUser.status == 412) {
                                this._log.print('result from check type user: user is type (3)');
                                this._configCredentials('NAO_CLIENTE');
                                this.dataUser.type = this._settings.TYPE_USER.NOT_CLIENT_UNREGISTER;
                                this._storage.setItem('storeUser', this.dataUser);
                                this._configTypeUser();
                                this._configInfoUser();
                                this._configShowUpdateRegister();
                                this.showLoading = false;
                              } else {
                                this._log.print('error from check type user:', errorCheckTypeUser);
                                if (!this.dataUser || !this.dataUser.urlError) {
                                  this._router.navigate(['/login']).then(() => {
                                    this.showLoading = false;
                                    this._router.navigate(['/alert']);
                                  });
                                } else {
                                  this.showLoading = false;
                                  window.top.location.href = this.dataUser.urlError;
                                }
                              }
                            },
                          );
                      }
                    },
                    errorTranslate => {
                      this._log.print('error from translate:', errorTranslate);
                      this._router.navigate(['/login']).then(() => {
                        this.showLoading = false;
                        this._router.navigate(['/alert']);
                      });
                    },
                  );
                },
                errorChangeKey => {
                  this._log.print('error from change key:', errorChangeKey);
                  this._router.navigate(['/login']).then(() => {
                    this.showLoading = false;
                    this._router.navigate(['/alert']);
                  });
                },
              );
          } else {
            if (this.controFirstAccess === true) {
              this._executeProcessWithoutDataParams();
            }
          }
        } else {
          if (this.controFirstAccess === true) {
            this._executeProcessWithoutDataParams();
          }
        }
      });
    } else {
      this.dataUser = this._storage.getItem('storeUser');
      this._configTypeUser();
      this._configInfoUser();
      this._configShowUpdateRegister();
      this.showLoading = false;
    }

    setTimeout(() => {
      this._closeLoading();
    }, 300);
  }

  public _executeProcessWithoutDataParams() {
    this._log.print('init commons process:');
    this.showLoading = false;
    this.dataUser.type = this._settings.TYPE_USER.CLIENT;
    this.typeUser = this._settings.TYPE_USER.CLIENT;
    this._storage.setItem('storeType', { type: this.typeUser });
    this._configInfoUser();
  }

  public _checkIfExistStoreUser(): boolean {
    if (this._storage.getItem('storeUser') === undefined) {
      return false;
    }

    return true;
  }

  public _configDataTranslate(params: DataInputs, result: any) {

    let cpf = decodeURI(params.cpf).replace(/ /g, '+');
    let name = decodeURI(params.name).replace(/ /g, '+');
    let phone = decodeURI(params.phone).replace(/ /g, '+');
    let urlSuccess = decodeURI(params.urlSuccess).replace(/ /g, '+');
    let urlError = decodeURI(params.urlError).replace(/ /g, '+');
    let partner = decodeURI(params.partner).replace(/ /g, '+');
    let ticket = decodeURI(params.ticket).replace(/ /g, '+')

    return {
      ticketFrom: {
        id: ticket,
        system: 'PAY',
      },
      ticketTo: {
        id: result.ticket,
        system: 'PAY',
      },
      contents: [
        {
          key: 'cpf',
          value: cpf,
        },
        {
          key: 'name',
          value: name,
        },
        {
          key: 'phone',
          value: phone,
        },
        {
          key: 'urlSuccess',
          value: urlSuccess
        },
        {
          key: 'urlError',
          value: urlError,
        },
        {
          key: 'partner',
          value: partner,
        },
      ],
      system: 'PAY',
    };
  }

  public _clearFields() {
    this.dataUser = {};
    this._storage.clear();
  }

  public _redirectUrlSucess(urlSuccess: string, resultAuth: any) {
    let path = encodeURI(urlSuccess);
    let tokenEncoded = encodeURIComponent(resultAuth.token);
    let storagecredentials = this._storage.getItem('credentials');
    let credentials = storagecredentials == undefined ? this.credentials : storagecredentials.type;
    let href = path + '?token=' + tokenEncoded + '&typeClient=' + credentials;
    
    setTimeout(() => {
      window.top.location.href = href;
    }, 300);
  }

  public _checkControlFirstAccess() {
    if (this.controFirstAccess == false) {
      this._storage.clear();
      this.controFirstAccess = true;
    }
  }

  public _storeDataFromAlreadyLDAP(cpf: any) {
    this._auth
      .changeKey({
        clientPublicKey: this.localPubKey,
        contextId: this._settings.CONTEXT_ID,
        system: 'LNC',
      })
      .then(
        resultChangeGetUser => {

          let cpfEncrypted = this.libCrypt.encryptToApp(cpf, resultChangeGetUser.serverPublicKey).toString();

          this._auth.getUserByCpf({
            documentNumber: cpfEncrypted,                                
            ticket: resultChangeGetUser.ticket,
            system: 'LNC',
            }).then(
              resultGetuser => {
                this.dataUser.name = this.libCrypt.decryptFromApp(resultGetuser.name, resultChangeGetUser.serverPublicKey);
                this.dataUser.phone =this.libCrypt.decryptFromApp(resultGetuser.phoneNumber, resultChangeGetUser.serverPublicKey);
                this._configAll();
                this._configCredentials('NAO_CLIENTE');
              },
              errorGetUser => {
                this._log.print('error from get user:', errorGetUser);
              },
            );
        },
        errorChangeGetUser => {
          this._log.print('error from change key to get user:', errorChangeGetUser);
        },
      );
  }

  public _closeLoading() {
    if (this.showLoading == true) {
      this.showLoading = false;
    }
  }

  public _configAll() {
    this._checkControlFirstAccess();
    this.showLoading = false;

    this._storage.setItem('storeUser', this.dataUser);

    this._configTypeUser();
    this._configInfoUser();
    this._configShowUpdateRegister();
  }
  public _configCredentials(credentials: any) {
    let type = credentials != 'NAO_CLIENTE' ? credentials : 'NAO_CLIENTE';
    this._storage.setItem('credentials', { type: type });
    this.credentials = type;
  }

  formataCPF(cpf) {
    this.dataUser.cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } 
}
