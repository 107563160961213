<div class="footer-component">
	<div class="container">
		<div class="row">
			<div class="col-md-4">
				<h4>Título</h4>
				<p>Lorem ipsum dolor, quisquam nesciunt fuga rerum!</p>
			</div>
			<div class="col-md-4">
				<h4>Título</h4>
				<p>Lorem ipsum dolor, quisquam nesciunt fuga rerum!</p>
			</div>
			<div class="col-md-4">
				<h4>Título</h4>
				<p>Lorem ipsum dolor, quisquam nesciunt fuga rerum!</p>
			</div>
		</div>
	</div>
</div>