<div class="container">
    <div class="row">
        <div class="col-md-12 text-center">
        	<div class="error-generic">
        		<p><img src="../../../assets/images/icon-alert.png"></p>
	            <h3 class="text-center">Error</h3>
	            <p><small>Desculpe, serviço indisponível no momento!</small></p>
	            <a (click)="goBack()" class="btn btn-lg btn-primary btn-signin-small">Voltar</a>
        	</div>
        </div>
    </div>
</div>