import {Injectable} from '@angular/core';
import { ActivatedRoute} from '@angular/router';

import {ENVIRONMENT} from '../environment';

export declare type ParamsDebug = {
    doDebug: string;
};

@Injectable({
    providedIn: 'root'
})
export class LogService {

    private _environment: string;

    constructor(private _activatedRoute: ActivatedRoute) {
        this._environment = ENVIRONMENT.development === true ? 'development' : 'production';
    }

    print(message: string, target?: any): void {

        this._activatedRoute.queryParams.subscribe((params: ParamsDebug) => {

            if (this._environment != 'development' && 'doDebug' in params) {

                if (params.doDebug == 'show')
                    this._environment = 'development';
            }

            if (this._environment == 'development') {
                if(target != undefined){
                        console.log(message, target);
                }else{
                    console.log(message);
                }
                
            }
        });


    }

}

