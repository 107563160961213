import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public APP_KEY: string = 'a6964780f21c013408bc0050569009ca';
  public APP_KEY_VIVERE: string = 'c1238870f89101341bb6005056906329';
  public APP_KEY_TOKEN: string = '52834a40f87d01341bb3005056906329'; 
  public APP_KEY_NOT_CLIENT: string = 'baebd740fdf20134090a0050569009ca';
  public CONTEXT_ID: string = '8fbbf95f1e5678899cb285b6051846a7';
  public CONTEXT_ID_SIMULATOR = '52f7accef9eab1db5ed275640454a4f4';

  //para desenvolvimento local descomentar a linha abaixo e comentar a linha subjacente.
  // public DOMAIN: string = 'https://esbapi.isbanbr.dev.corp/';
  // public DOMAIN: string = 'https://esbapi.santanderbr.pre.corp/';
  public DOMAIN: string = '__hubhost__';
  
  public TYPE_USER: any = {
    CLIENT: 1,
    NOT_CLIENT_REGISTER: 2,
    NOT_CLIENT_UNREGISTER: 3,
  };
  
  public ERROR_MESSAGES: any = {
    CPF: 'O cpf informado é inválido, Por favor, tente novamente.',
    PHRASE: 'A senha digitada é inválida. Por favor, tente novamente.',
    PHRASE_CONFIRMATION: 'A senha de confirmação não confere com a senha digitada, tente novamente.',
    TOKEN: 'O token informado é inválido, tente novamente ou reenvie outro token.',
    EMAIL: 'O e-mail informado é inválido, tente novamente.',
    PHONE: 'O telefone informado é inválido, tente novamente.',
    AUTH: 'As credenciais informadas não conferem, tente novamente.',
    NOT_CLIENT: 'Desculpe! Não encontramos seus dados em nossa base.',
    GENERIC: 'Desculpe! Ocorreu um erro em nosso sistema, tente novamente.',

    USER_OR_PHRASE_INVALID: 'Usuário ou senha inválidos',
    ACTION_REQUIRED:
      'Por medida de segurança, antes de prosseguir efetue o acesso ao App Santander ou Internet Banking',
  };

  public INFO: any = {
    FORGOT_PHRASE: 'Para cadastrar uma nova senha acesse os canais digitais Santander disponíveis.',
  };

  public LINK_FORGOT_PHRASE: any =
    'https://www.santander.com.br/br/pessoa-fisica/santander?segmento=/pessoa-fisica/santander';
}
