<div class="header-component">
	<div class="container">
		<div class="row">
			<div class="col-md-2">
				<a href="#" class="brand"><img class="img-responsive" src="../../../assets/images/logo.jpg" alt=""></a>
			</div>
			<div class="col-md-10">
				<ul class="menu">
					<li>
						<a href="#">Home</a>
					</li>
					<li>
						<a href="#">Sobre</a>
					</li>
					<li>
						<a href="#">Projetos</a>
					</li>
					<li>
						<a href="#">Contatos</a>
					</li>
					<div class="clearfix"></div>
				</ul>
			</div>
		</div>
	</div>
</div>