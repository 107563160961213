import { Component , OnInit , ViewChild , AfterViewInit , ElementRef } from '@angular/core';

@Component({
  selector   : 'menu-component',
  templateUrl: './menu.component.html'
})

export class MenuComponent implements OnInit {

	constructor(){

	}

	ngOnInit(){}

}