import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules, CanActivate } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { LoginPfComponent } from './login/loginpf.component';
import { LoginMPfComponent } from './login/loginmpf.component';
import { TokenComponent } from './token/token.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { UpdateRegisterComponent } from './update-register/update-register.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ErrorGenericComponent } from './error/error-generic.component';
import { AddRegisterComponent } from './add-register/add-register.component';

import { AuthGuard } from './services/auth-guard.service';
import { AuthGuardLogin } from './services/auth-guard-login.service';

export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'simulator', component: HomeComponent }, 
  { path: 'login', component: LoginComponent, canActivate: [AuthGuardLogin] },
  { path: 'loginpf', component: LoginPfComponent, canActivate: [AuthGuardLogin] },
  { path: 'loginmpf', component: LoginMPfComponent, canActivate: [AuthGuardLogin]},
  { path: 'token', component: TokenComponent, canActivate: [AuthGuardLogin] },
  { path: 'new-password', component: NewPasswordComponent, canActivate: [AuthGuardLogin] },
  { path: 'update-register', component: UpdateRegisterComponent, canActivate: [AuthGuardLogin] },
  { path: 'alert', component: ErrorGenericComponent },
  { path: 'add-register', component: AddRegisterComponent, canActivate: [AuthGuardLogin] },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
export const RoutingComponents = [
  HomeComponent,
  LoginComponent,
  TokenComponent,
  NewPasswordComponent,
  UpdateRegisterComponent,
  NotFoundComponent,
  ErrorGenericComponent,
  AddRegisterComponent,
];
