import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { CommonModule } from '@angular/common';
import { Http } from '@angular/http';
// import { TranslateStaticLoader, TranslateLoader, TranslateModule } from 'ng2-translate';

// import { JsonConfigModule } from 'angl-spawebbgrl/json-config-module/config.module';
// import { HubConnectorModule } from 'angl-spawebbgrl/spa-http-module/hub-connector.module';
// import { ErrorHandlingModule } from 'angl-spawebbgrl/error-handling-module';
// import { LogModule } from 'angl-spawebbgrl/log';
import { AppComponent } from './app.component';

//modules
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-modialog';
import { FormsModule } from '@angular/forms';
import { BootstrapModalModule } from 'ngx-modialog/plugins/bootstrap';

//routing
import { AppRoutingModule } from './app-routing.module';
import { RoutingComponents } from './app-routing.module';

//components
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { LoginPfComponent } from './login/loginpf.component';
import { LoginMPfComponent } from './login/loginmpf.component';

//services
import { SettingsService } from './services/settings.service';
import { StorageService } from './services/storage.service';
import { WindowRef } from './services/windowRef.service';
import { AuthGuard } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { LogService } from './services/log.service';
import { AuthGuardLogin } from './services/auth-guard-login.service';

//pipes
import { UcwordPipe } from './pipes/ucword.pipe';
import { CpfFakePipe } from './pipes/cpf-fake.pipe';
import { PhoneFakePipe } from './pipes/phone-fake.pipe';
import { StripNamePipe } from './pipes/strip-name.pipe';
import { LimitCaracterePipe } from './pipes/limit-caractere.pipe';
import { PhoneProtectedPipe } from './pipes/phone-protected.pipe';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    RoutingComponents,
    UcwordPipe,
    CpfFakePipe,
    PhoneFakePipe,
    StripNamePipe,
    LimitCaracterePipe,
    PhoneProtectedPipe,
    LoginPfComponent,
    LoginMPfComponent,
  ],
  imports: [
    BrowserModule,
    // ErrorHandlingModule,
    // JsonConfigModule.forRoot(['assets/url.config.json']),
    // TranslateModule.forRoot({
    //   provide: TranslateLoader,
    //   useFactory: (http: Http) => new TranslateStaticLoader(http, 'assets/translate', '.json'),
    //   deps: [Http],
    // }),
    // HubConnectorModule.forRoot('url_hub_sso'),
    // LogModule.forRoot('url_log'),

    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    BootstrapModalModule,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    SettingsService,
    StorageService,
    WindowRef,
    AuthService,
    AuthGuard,
    LogService,
    AuthGuardLogin,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
