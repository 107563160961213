import { Pipe, PipeTransform } from '@angular/core';

@Pipe({

	name: 'stripname'
})
export class StripNamePipe implements PipeTransform {

	transform(value: string) {

		if(value ===undefined || !value.length) return value;
		
		let stripname = value.replace(/(\w)\s(.*)/, "$1");

		return stripname;
	}
}
