import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { SettingsService } from './settings.service'; 
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private _headers = new HttpHeaders({
    'content-type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  });

  private _headerSSA = new HttpHeaders({
    'content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'gw-app-key': '52834a40f87d01341bb3005056906329'
  })

  constructor(private _settings: SettingsService, private _storage: StorageService, private _http: HttpClient) {}

  isLoggedIn(): boolean {
    if (this._storage.getItem('jwttoken') == undefined) {
      return false;
    }
    return true;
  }

  authenticateClient(data: any): Promise<any> {
    let path = 'security/v1/authenticate/external/custumer?gw-app-key=' + this._settings.APP_KEY;

    return this._http
      .post('/hub-url/' + path, data, { headers: this._headers })
      .toPromise() 
  }

  authenticate(data: any): Promise<any> {
    let path = 'customer-security/v1/authenticate?gw-app-key=' + this._settings.APP_KEY_NOT_CLIENT; 

    return this._http
      .post('/hub-url/' + path, data, { headers: this._headers })
      .toPromise()
  }

  validateSmsToken(data: any): Promise<any> {
    let path = 'security/v1/check-token-non-customer?gw-app-key=' + this._settings.APP_KEY_TOKEN;

    return this._http
      .post('/hub-url/' + path, data, { headers: this._headers })
      .toPromise()
  }

  // Serviço antigo tem que realizar a mudança para o novo serviço
  sendSmsToken(data: any): Promise<any> {
    let path = 'security/v1/generate-token-non-customer?gw-app-key=' + this._settings.APP_KEY_TOKEN;
    return this._http
      .post('/hub-url/' + path, data, { headers: this._headers })
      .toPromise()
  }
  // =============================================================
  // novos serviços =============================================
  generateToken(data: any): Promise<any> {
    let path = 'security-device/v2/sms/create?gw-app-key=' + this._settings.APP_KEY_TOKEN  + '&integrationKey=' + this._settings.APP_KEY_TOKEN;
    return this._http
    .post('/hub-url/' + path, data, {headers: this._headers}).toPromise()
  }

  validateTokenSMS(data: any) : Promise<any> {

    let path = 'security-device/v2/sms/validate?gw-app-key=' + this._settings.APP_KEY_TOKEN;
    return this._http
    .put('/hub-url/' + path, data, {headers: this._headers})
    .toPromise()
  }
  // ==============================================================

  updateRegister(data: any): Promise<any> { //Analisar fluxo de utilização
    let path = 'non-customer-access-mgmt/v2/user?gw-app-key=' + this._settings.APP_KEY_TOKEN;

    return this._http
      .put('/hub-url/' + path, data, { headers: this._headers })
      .toPromise()
  }

  changePassword(data: any): Promise<any> {
    let path = 'non-customer-access-mgmt/v2/password?gw-app-key=' + this._settings.APP_KEY_TOKEN;

    return this._http
      .put('/hub-url/' + path, data, { headers: this._headers })
      .toPromise()
  }

  forgotPassword(data: any): Promise<any> {
    let path = 'non-customer-access-mgmt/v2/password?gw-app-key=' + this._settings.APP_KEY_TOKEN;

    return this._http
      .put('/hub-url/' + path, data, { headers: this._headers})
      .toPromise()
  }

  checkTypeUser(data: any): Promise<any> {
    let path = 'customer-security/v1/credential-type?gw-app-key=' + this._settings.APP_KEY;

    return this._http
      .post('/hub-url/' + path, data, { headers: this._headers })
      .toPromise()
  }

  register(data: any): Promise<any> {
    let path = 'non-customer-access-mgmt/v2/user?gw-app-key=' + this._settings.APP_KEY_TOKEN;

    return this._http
      .post('/hub-url/' + path, data, { headers: this._headers })
      .toPromise()
  }

  getUserByCpf(data: any): Promise<any> { 
    let path = 'non-customer-access-mgmt/v2/retrieve-user?gw-app-key=' + this._settings.APP_KEY_TOKEN;
    
    return this._http
      .post('/hub-url/' + path, data, { headers: this._headers })
      .toPromise()
  }

  changeKey(data: any): Promise<any> {
    let path = 'cryptographic-security/v1/key/public/js?gw-app-key=' + this._settings.APP_KEY;

    return this._http
      .post('/hub-url/' + path, data, { headers: this._headers })
      .toPromise()
  }

  translate(data: any): Promise<any> {
    let path = 'cryptographic-security/v1/change-context?gw-app-key=' + this._settings.APP_KEY; 

    return this._http.post('/hub-url/' + path, data, { headers: this._headers }).toPromise()
  }

  // private _handleError(error: any): Promise<any> {
  //   // let responseHeader = error.headers as ResponseHeader;
  //   // this._storeSessionUid(responseHeader._headers);
    
  //   let erroPayload = error.json();
  //   erroPayload.httpStatus = error.status;

  //   return Promise.reject(erroPayload);
  // }

  // private _storeSessionUid(headers: Headers) {
  //   let xAccessToken = headers.get('x-access-token');
  //   let xuid = headers.get('x-uid');

  //   if (xAccessToken != undefined && xuid != undefined) {
  //     let authorization = 'Bearer ' + btoa(xuid[0] + ':' + xAccessToken[0]);
  //     let jwttoken = {
  //       xAccessToken: xAccessToken[0],
  //       xuid: xuid[0],
  //       authorization: authorization,
  //     };

  //     this._storage.setItem('jwttoken', jwttoken);
  //   }
  // }

  // private _headersWithAuthorization(): Headers {
  //   let jwttoken = this._storage.getItem('jwttoken');
  //   let authorization = jwttoken.authorization;
  //   let headersWithAuthorization = new Headers({
  //     'content-type': 'application/json',
  //     'Access-Control-Allow-Origin': '*',
  //     Authorization: authorization,
  //   });

  //   return headersWithAuthorization;
  // }

}
