import { Pipe, PipeTransform } from '@angular/core';

@Pipe({

	name:'cpffake'
})
export class CpfFakePipe implements PipeTransform{

	transform(value: string ){

		if(value ===undefined || !value.length) return value;
		
		let cpfFake = value.replace(/\D/g,"");
			cpfFake = cpfFake.replace(/(\d{3})(\d)/,"$1.$2");
			cpfFake = cpfFake.replace(/(\d{3})(\d)/,"***.$2");
			cpfFake = cpfFake.replace(/(\d{3})(\d)/,"***-$2");

		return cpfFake;
	}
}
