import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';

import { StorageService } from '../services/storage.service';

@Component({
    selector: 'error-generic',
    templateUrl: './error-generic.component.html'
})
export class ErrorGenericComponent implements OnInit {

    dataUser: any;

    constructor(
        private _storage: StorageService,
        private _router: Router,
        private _location: Location
    ) { }

    ngOnInit() {
        this._configDataUser();
    }

    goBack() {

        if (!this.dataUser || !this.dataUser.urlError) {
            this._router.navigate(['/alert']).then(() => {
                this._location.back();
            });
        } else {
            this._router.navigate(['/alert']).then(() => {
                window.top.location.href = this.dataUser.urlError;
            });
        }

    }
    public _configDataUser() {

        this.dataUser = this._storage.getItem('storeUser');
    }



}
