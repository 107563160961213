import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate} from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private _auth: AuthService, private _router: Router) { }

    canActivate() {

        if (!this._auth.isLoggedIn()) {
            this._router.navigate(['/login']);
            return false;
        }

        return true;

    }

}